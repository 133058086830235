import bridge from '@vkontakte/vk-bridge';
import getUserGenderForVk, { getUserInterestedInForVk } from '../../utils/users/getUserGender';
import countriesInRussian from '../../utils/countries/countriesInRussian';
import { VKUserInfo } from '../../interfaces/GameTableInterfaces';
import connectToSocket from '../../utils/socket/connectToSocket';
import imageUpload from '../../utils/upload/cloudinaryUpload';

export default function VKInit({ getUser, updateUser }: { getUser: any, updateUser: any }) {
  bridge.send('VKWebAppInit')
    .then(() => bridge.send('VKWebAppGetUserInfo'))
    .then(async (response: any) => {
      const VKUserInfo: VKUserInfo = response;
      getUser({ id: VKUserInfo.id }).then(async response => {
        const { user, token } = response.payload;

        if (!user.firstName || !user.birthday) {
          const avatar: any = await imageUpload(VKUserInfo.photo_max_orig) || ''

          const getBirthday = (bdateVisibility: number) => {
            if (bdateVisibility === 1 && VKUserInfo?.bdate) {
              const dateString = VKUserInfo.bdate;
              const [day, month, year] = dateString.split('.');
              const formattedDate = `${month}/${day.length < 2 ? '0' : ''}${day}/${year}`;
              return new Date(formattedDate)
            }

            return null
          }

          const data = {
            ISO: countriesInRussian.find(item => item.text === VKUserInfo?.country?.title)?.value?.toUpperCase() || 'RU',
            firstName: VKUserInfo?.first_name || '',
            lastName: VKUserInfo?.last_name || '',
            sex: getUserGenderForVk(VKUserInfo?.sex),
            interestedIn: getUserInterestedInForVk(VKUserInfo?.sex),
            birthday: getBirthday(VKUserInfo?.bdate_visibility),
            avatar: avatar?.data.public_id,
            interfaceLang: 'RU',
          }

          updateUser(data, user.id).then(() => {
            getUser({ id: VKUserInfo.id }).then(response => {
              const { user, token } = response.payload;
              connectToSocket(token, user, false)
            })
          })
        } else {
          connectToSocket(token, user, true)
        }


        if (!user.firstLogin) {
          setTimeout(() => {
            bridge.send('VKWebAppAddToFavorites', {})
          }, 300000);
        }
      })
    })
}