import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import RegistrationWrapper from './Registration'
import Login from './Login'

import authBack from '../../../assets/images/auth-back.png'
import AuthWithSocial from './AuthWithSocial'
import ResetPassword from './ResetPassword'

export default function Authentication() {
  const { t } = useTranslation()

  const [authStatus, setAuthStatus] = useState<'login' | 'reg' | 'reset'>('login')

  const handleChangeAuthStatus = useCallback((status: 'login' | 'reg' | 'reset') => {
    setAuthStatus(status)
  }, [])

  return (
    <div className='auth'>
      <div className='auth-back'>
        <img src={authBack} alt='authBack' />
      </div>
      <div className='auth-wrapper'>
        {authStatus === 'login' && <Login
          handleChangeAuthStatus={handleChangeAuthStatus}
        />}
        {authStatus === 'reg' && <RegistrationWrapper
          handleChangeAuthStatus={handleChangeAuthStatus}
        />}
        {authStatus === 'reset' && <ResetPassword
          handleChangeAuthStatus={handleChangeAuthStatus}
        />}
        <AuthWithSocial />
      </div>
      <div className='auth-topBlock'>
        {authStatus === 'login' ? <span>
          Don’t have an account?
          <b onClick={() => handleChangeAuthStatus('reg')}>Sign Up</b>
        </span> : <span>
          {t('registration.alreadyHaveAccount')}
          <b onClick={() => handleChangeAuthStatus('login')}>{t('login.login')}</b>
        </span>}
      </div>
    </div>
  )
}