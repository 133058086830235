import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'
import { clanInvitation, singleClan } from '../../interfaces/ClansInterfaces'
import { clansIconsUpload } from '../../utils/upload/bunnyUpload'
import { loadingStatus } from '../../interfaces/MainInterfaces'
import { ClanModalSections, ClanSearchSequenceBy } from '../../enums'
import AuthAPI from '../../services/AuthAPI'
import { RootState } from '../store'
import usersList from '../../utils/tutorial/createBots'

interface initState {
  status: loadingStatus,
  clans: {
    clans: [],
    count: number
  } | null,
  clanById: {
    status: loadingStatus,
    clan: singleClan | null
  },
  clansList: {
    daily: any[],
    weekly: any[],
    monthly: any[]
  },
  createClanStatus: loadingStatus,
  editClanStatus: loadingStatus,
  invitations: clanInvitation[],
  clanInfo: {
    activeSection: ClanModalSections,
    openClansModal: boolean
  }
}

const initialState: initState = {
  status: 'loading',
  clans: null,
  createClanStatus: 'loaded',
  editClanStatus: 'loaded',
  clanById: {
    status: 'loaded',
    clan: null,
  },
  clansList: {
    daily: [],
    weekly: [],
    monthly: [],
  },
  invitations: [],
  clanInfo: {
    activeSection: ClanModalSections.ALL,
    openClansModal: false,
  },
}

export const getClans = createAsyncThunk(
  'clans/getClans',
  async (search: string) => {
    const response = await AuthAPI.get(`/clan/get/many?${search}`)

    return response.data
  },
)

export const getClanById = createAsyncThunk(
  'clans/getClanById',
  async (id: number) => {
    const response = await AuthAPI.get(`/clan/get/one/${id}`)

    return response.data
  },
)

export const createClan = createAsyncThunk(
  'clans/createClan',
  async (data: {
    title: string,
    icon: File,
    cb: any
  }, { rejectWithValue }) => {
    try {
      let image: any = data.icon || '';
      if (image) {
        image = await clansIconsUpload(image)
      }

      const response = await AuthAPI.post('/clan/add', {
        title: data.title,
        iconPath: image,
      })

      data.cb(response)

      return response.data
    } catch (err: any) {
      if (!err.response) {
        throw err
      }

      return rejectWithValue(err.response.data)
    }
  },
)

export const editClan = createAsyncThunk(
  'clans/editClan',
  async (data: {
    title: string,
    icon: File,
    cb: any,
    clanId: number
  }, { rejectWithValue }) => {
    try {
      let image: any = data.icon || undefined;
      if (image) {
        image = await clansIconsUpload(image)
      }

      const response = await AuthAPI.put(`/clan/update/${data.clanId}`, {
        title: data.title,
        iconPath: image,
      })

      data.cb()

      return response.data
    } catch (err: any) {
      if (!err.response) {
        throw err
      }

      return rejectWithValue(err.response.data)
    }
  },
)

export const sendInvite = createAsyncThunk(
  'clans/sendInvite',
  async (data: {
    userId: number,
    clanId: number
  }) => {
    const response = await AuthAPI.post('/clan/invite/send', data)

    return response.data
  },
)

export const answerInvite = createAsyncThunk(
  'clans/acceptInvite',
  async (data: {
    clanId: number,
    accept: boolean
  }) => {
    const response = await AuthAPI.post('/clan/ans/invite', data)

    return response.data
  },
)

export const kickUserFromClan = createAsyncThunk(
  'clans/acceptInvite',
  async (data: {
    kickedId: number,
    clanId: number
  }) => {
    const response = await AuthAPI.post('/clan/kick', data)

    return response.data
  },
)

export const buyLeadership = createAsyncThunk(
  'clans/buyLeadership',
  async (id: number) => {
    const response = await AuthAPI.post(`clan/buy/leadership/${id}`)

    return response.data
  },
)
export const getMyInvitations = createAsyncThunk(
  'clans/getMyInvitations',
  async () => {
    const response = await AuthAPI.get('/clan/user/invitations/my')

    return response.data
  },
)

export const cashingOutBonus = createAsyncThunk(
  'clans/cashingOutBonus',
  async (count: number) => {
    const response = await AuthAPI.post('/user/cashing-out/clan-bonus', {
      count,
    })

    return response.data
  },
)

export const clanLeave = createAsyncThunk(
  'clans/clanLeave',
  async () => {
    const response = await AuthAPI.post('/clan/leave')

    return response.data
  },
)

export const clanDelete = createAsyncThunk(
  'clans/clanDelete',
  async (id: number) => {
    const response = await AuthAPI.delete(`/clan/delete/${id}`)

    return response.data
  },
)

export const getClansByFilter = createAsyncThunk(
  'clans/getClansByFilter',
  async (type: ClanSearchSequenceBy) => {
    const response = await AuthAPI.get(`/clan/get/many?skip=0&take=10&title&sequenceBy=${type}&sequenceUp=false`)

    return {
      list: response.data,
      type,
    }
  },
)

export const defendClan = createAsyncThunk(
  'clans/defendClan',
  async () => {
    const response = await AuthAPI.post('/clan/defend')

    // return {
    //   list: response.data,
    //   type,
    // }
  },
)

const clansSlice = createSlice({
  name: 'clans',
  initialState,
  reducers: {
    changeClanInfo: (state, action: PayloadAction<{
      activeSection?: ClanModalSections,
      openClansModal?: boolean
    }>) => {
      state.clanInfo = {
        ...state.clanInfo,
        ...action.payload,
      }
    },
    setTutorialClan: (state, action) => {
      state.clanById.clan = {
        id: 324,
        level: 1,
        points: 0,
        iconPath: '',
        title: '321',
        deleted: false,
        dailyPoints: 0,
        weeklyPoints: 0,
        monthlyPoints: 0,
        defended: false,
        defendedEndDate: '2024-04-10T06:07:57.886Z',
        members: [...usersList, action.payload] as any,
        leader: usersList[1] as any,
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getClans.pending, state => {
      state.status = 'loading'
    })
    builder.addCase(getClans.fulfilled, (state, action) => {
      state.clans = {
        ...action.payload,
      }

      state.status = 'loaded'
    })

    builder.addCase(getClanById.pending, state => {
      state.clanById.status = 'loading'
    })
    builder.addCase(getClanById.fulfilled, (state, action: PayloadAction<{
      clan: singleClan
    }>) => {
      state.clanById.clan = {
        ...action.payload.clan,
      }

      state.clanById.status = 'loaded'
    })

    builder.addCase(createClan.pending, state => {
      state.createClanStatus = 'loading'
    })
    builder.addCase(createClan.fulfilled, (state, action) => {
      state.createClanStatus = 'loaded'

      toast.success('Your clan is successfully created')
    })
    builder.addCase(createClan.rejected, (state, action: any) => {
      state.createClanStatus = 'loaded'

      toast.error(action.payload.message)
    })

    builder.addCase(editClan.pending, state => {
      state.editClanStatus = 'loading'
    })
    builder.addCase(editClan.fulfilled, (state, _) => {
      state.editClanStatus = 'loaded'

      toast.success('Your clan is successfully created')
    })
    builder.addCase(editClan.rejected, (state, action: any) => {
      state.editClanStatus = 'loaded'

      toast.error(action.payload.message)
    })

    builder.addCase(getMyInvitations.fulfilled, (state, action) => {
      state.invitations = action.payload.response
    })

    builder.addCase(kickUserFromClan.fulfilled, (_, action) => {
      toast.success(action.payload.message)
    })

    builder.addCase(getClansByFilter.fulfilled, (state, action) => {
      const clansTopTypes = {
        [ClanSearchSequenceBy.DAILY_POINTS]: 'daily',
        [ClanSearchSequenceBy.WEEKLY_POINTS]: 'weekly',
        [ClanSearchSequenceBy.MONTHLY_POINTS]: 'monthly',
      }

      state.clansList[clansTopTypes[action.payload.type]] = action.payload.list.clans
    })
  },
})

export const {
  changeClanInfo,
  setTutorialClan,
} = clansSlice.actions

export const getSingleClanStatusInfo = (state: RootState) => state.clans.clanById.status
export const getClanCreateInfo = (state: RootState) => state.clans.createClanStatus
export const getClanEditInfo = (state: RootState) => state.clans.editClanStatus
export const getSingleClanInfo = (state: RootState) => state.clans.clanById
export const getClansModalInfo = (state: RootState) => state.clans.clanInfo
export const getClansStatusInfo = (state: RootState) => state.clans.status
export const getClansLists = (state: RootState) => state.clans.clansList
export const getClansInfo = (state: RootState) => state.clans.clans

export default clansSlice.reducer