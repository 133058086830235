import { useEffect } from 'react';
import { getPrivateMessages } from '../../../app/slices/messagesSlice';
import { useAppDispatch } from '../../../app/hooks';

export default function MessagesSocket() {
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(getPrivateMessages('skip=0&take=1000'))
  }, []);
}