/* eslint-disable import/prefer-default-export */

type envTypes = 'vk' | 'ok' | 'fb' | 'none' | 'ml' | ''

export default function getCurrentEnv(): envTypes {
  const appEnv: any = process.env.REACT_APP_ENV || ''

  return appEnv
}

export function getCurrentDeviceEnv() {
  const deviceEnv = process.env.REACT_APP_DEVICE_ENV || ''

  return deviceEnv
}

export function getSocialEnv() {
  if (
    getCurrentEnv() === 'vk'
    || getCurrentEnv() === 'fb'
    || getCurrentEnv() === 'ok'
    || getCurrentEnv() === 'ml'
  ) {
    return true
  }

  return false
}

export function getSocialEnvForFB() {
  if (
    getCurrentEnv() === 'vk'
    || getCurrentEnv() === 'ok'
    || getCurrentEnv() === 'ml'
  ) {
    return true
  }

  return false
}