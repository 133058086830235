import dayjs from 'dayjs';
import {
  FormControl, FormHelperText, MenuItem, Select, TextField,
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { AuthButton } from '../../../../components/Authentication';
import { GenderEnum } from '../../utils/enums';

interface props {
  handleChangePage: (page: number) => void,
  next: number
}


export default function RegistrationPart1({
  handleChangePage,
  next,
}: props) {
  const { t } = useTranslation()

  const {
    handleSubmit, control, formState: { errors },
  } = useForm({
    defaultValues: {
      firstName: '',
      lastName: '',
      gender: GenderEnum.male,
      birthday: dayjs().subtract(18, 'year'),
    },
  });

  const onSubmit = (data: any) => {
    sessionStorage.setItem('firstName', data.firstName)
    sessionStorage.setItem('birthday', data.birthday)
    sessionStorage.setItem('lastName', data.lastName)
    sessionStorage.setItem('gender', data.gender)

    handleChangePage(next)
  };

  return (
    <div className='auth-form'>
      <div className='auth-form-body'>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormControl variant='outlined' className='auth-input'>
            <Controller
              control={control}
              name='firstName'
              rules={{
                required: {
                  value: true,
                  message: t('errors.required'),
                },
              }}
              render={({
                field: {
                  onChange, value,
                },
              }) => (
                <TextField
                  id='outlined-basic'
                  placeholder='John'
                  variant='outlined'
                  error={!!errors.firstName}
                  value={value}
                  onChange={onChange}
                />
              )}
            />
            {!!errors.firstName && (
              <FormHelperText error id='accountId-error'>
                {errors.firstName.message}
              </FormHelperText>
            )}
          </FormControl>
          <FormControl variant='outlined' className='auth-input'>
            <Controller
              control={control}
              name='lastName'
              rules={{
                required: {
                  value: true,
                  message: t('errors.required'),
                },
              }}
              render={({
                field: {
                  onChange, value,
                },
              }) => (
                <TextField
                  id='outlined-basic'
                  placeholder='Smith'
                  variant='outlined'
                  error={!!errors.lastName}
                  value={value}
                  onChange={onChange}
                />
              )}
            />
            {!!errors.lastName && (
              <FormHelperText error id='accountId-error'>
                {errors.lastName.message}
              </FormHelperText>
            )}
          </FormControl>
          <FormControl variant='outlined' className='auth-input'>
            <Controller
              control={control}
              name='gender'
              render={({
                field: {
                  onChange, value,
                },
              }) => (
                <Select
                  labelId='demo-simple-select-label'
                  id='demo-simple-select'
                  value={value}
                  onChange={onChange}
                >
                  <MenuItem className='genderSelectorMenuItem' value={GenderEnum.male}>
                    {t('registration.male')}
                  </MenuItem>
                  <MenuItem className='genderSelectorMenuItem' value={GenderEnum.female}>
                    {t('registration.female')}
                  </MenuItem>
                </Select>
              )}
            />
          </FormControl>
          <FormControl variant='outlined' className='auth-input'>
            <Controller
              control={control}
              name='birthday'
              render={({
                field: {
                  onChange, value,
                },
              }) => (
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label={t('profile.info.birthDate')}
                    value={value}
                    PopperProps={{ sx: { zIndex: 1000000 } }}
                    InputProps={{ sx: { background: '#fff' } }}
                    onChange={onChange}
                    maxDate={dayjs().subtract(18, 'year')}
                    renderInput={(params) => <TextField className='age-modal-input-field' {...params} />}
                  />
                </LocalizationProvider>
              )}
            />
            {!!errors.birthday && (
              <FormHelperText error id='accountId-error'>
                {errors.birthday.message}
              </FormHelperText>
            )}
          </FormControl>
          <AuthButton>{t('buttons.next')}</AuthButton>
        </form>
      </div>
    </div>
  )
}