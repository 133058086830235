import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { RootState } from '../store'

interface initState {
  settings: {
    open: boolean,
  }
  search: {
    open: boolean,
  }
  notifications: {
    open: boolean,
  }
  luckyStatusModal: {
    open: boolean,
  }
  topModal: {
    open: boolean,
  }
  supportModal: {
    open: boolean,
  }
  newsModal: {
    open: boolean,
  }
  privateRoomModal: {
    open: boolean,
  }
  welcomeModal: {
    open: boolean,
  }
  createGroupChatModal: {
    open: boolean,
  },
  premiumStatusModal: {
    open: boolean,
  },
  photoBanner: {
    open: boolean,
    url: string
  },
}

const initialState: initState = {
  settings: {
    open: false,
  },
  search: {
    open: false,
  },
  notifications: {
    open: false,
  },
  luckyStatusModal: {
    open: false,
  },
  supportModal: {
    open: false,
  },
  topModal: {
    open: false,
  },
  newsModal: {
    open: false,
  },
  privateRoomModal: {
    open: false,
  },
  welcomeModal: {
    open: false,
  },
  createGroupChatModal: {
    open: false,
  },
  premiumStatusModal: {
    open: false,
  },
  photoBanner: {
    open: false,
    url: '',
  },
}

const modals = createSlice({
  name: 'modals',
  initialState,
  reducers: {
    setSettingsOpen: (state, action: PayloadAction<{
      open: boolean
    }>) => {
      state.settings.open = action.payload.open
    },
    setSearchOpen: (state, action: PayloadAction<{
      open: boolean
    }>) => {
      state.search.open = action.payload.open
    },
    setNotificationsOpen: (state, action: PayloadAction<{
      open: boolean
    }>) => {
      state.notifications.open = action.payload.open
    },
    setLuckyModalOpen: (state, action: PayloadAction<{
      open: boolean
    }>) => {
      state.luckyStatusModal.open = action.payload.open
    },
    setTopModalOpen: (state, action: PayloadAction<{
      open: boolean
    }>) => {
      state.topModal.open = action.payload.open
    },
    setSupportModalOpen: (state, action: PayloadAction<{
      open: boolean
    }>) => {
      state.supportModal.open = action.payload.open
    },
    setNewsModalOpen: (state, action: PayloadAction<{
      open: boolean
    }>) => {
      state.newsModal.open = action.payload.open
    },
    setPrivateRoomModalOpen: (state, action: PayloadAction<{
      open: boolean
    }>) => {
      state.privateRoomModal.open = action.payload.open
    },
    setWelcomeModalOpen: (state, action: PayloadAction<{
      open: boolean
    }>) => {
      state.welcomeModal.open = action.payload.open
    },
    setCreateGroupChatModalOpen: (state, action: PayloadAction<{
      open: boolean
    }>) => {
      state.createGroupChatModal.open = action.payload.open
    },
    setPremiumStatusModalOpen: (state, action: PayloadAction<{
      open: boolean
    }>) => {
      state.premiumStatusModal.open = action.payload.open
    },
    setPhotoModalOpen: (state, action: PayloadAction<{
      open: boolean,
      url: string
    }>) => {
      state.photoBanner = action.payload
    },
  },
  extraReducers: (builder) => {

  },
})

export const {
  setCreateGroupChatModalOpen,
  setPremiumStatusModalOpen,
  setPrivateRoomModalOpen,
  setNotificationsOpen,
  setWelcomeModalOpen,
  setSupportModalOpen,
  setLuckyModalOpen,
  setPhotoModalOpen,
  setNewsModalOpen,
  setTopModalOpen,
  setSettingsOpen,
  setSearchOpen,
} = modals.actions

export const getCreateGroupChatModalOpenStatus = (state: RootState) => state.modals.createGroupChatModal.open;
export const getPrivateRoomModalOpenStatus = (state: RootState) => state.modals.privateRoomModal.open;
export const getPremiumStatusOpenStatus = (state: RootState) => state.modals.premiumStatusModal.open;
export const getNotificationsOpenStatus = (state: RootState) => state.modals.notifications.open;
export const getPhotoModalOpenStatus = (state: RootState) => state.modals.photoBanner;
export const getLuckyModalOpenStatus = (state: RootState) => state.modals.luckyStatusModal.open;
export const getWelcomeOpenStatus = (state: RootState) => state.modals.welcomeModal.open;
export const getSupportOpenStatus = (state: RootState) => state.modals.supportModal.open;
export const getNewsModalOpenStatus = (state: RootState) => state.modals.newsModal.open;
export const getTopModalOpenStatus = (state: RootState) => state.modals.topModal.open;
export const getSettingsOpenStatus = (state: RootState) => state.modals.settings.open;
export const getSearchOpenStatus = (state: RootState) => state.modals.search.open;

export default modals.reducer