/* eslint-disable no-lonely-if */
import { useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';
import {
  Box, CircularProgress, FormControl, MenuItem, Select,
} from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import {
  authLogin,
  getMe, getRegistrationStatus, updateUserInfo,
} from '../../../../app/slices/usersSlice';
import getCurrentEnv, { getSocialEnv } from '../../../../utils/variables';
import connectToSocket from '../../../../utils/socket/connectToSocket';
import { AuthButton } from '../../../../components/Authentication';
import { UserInterested } from '../../utils/enums';
import countries from '../../utils/countries';

interface props {
  handleChangePage: (page: number | null) => void,
  next: null | number
  handleChangeAuthStatus?: (status: 'login' | 'reg') => void
}


export default function RegistrationPart2({
  handleChangePage,
  handleChangeAuthStatus,
  next,
}: props) {
  const { t } = useTranslation()

  const dispatch = useAppDispatch()

  const navigate = useNavigate()

  const [loading, setLoading] = useState<boolean>(false)

  const {
    handleSubmit, control,
  } = useForm({
    defaultValues: {
      country: countries[0].code,
      interestedIn: UserInterested.female,
      file: null,
    },
  });

  const fileInputRef = useRef<any>(null)

  const onSubmit = ({ interestedIn, country }: any) => {
    const userId = localStorage.getItem('userId')
    const firstName = sessionStorage.getItem('firstName') || ''
    const lastName = sessionStorage.getItem('lastName') || ''
    const birthday = sessionStorage.getItem('birthday');
    const file = fileInputRef.current.files[0] || '';
    const gender = sessionStorage.getItem('gender');

    if (file?.size > 2000000) {
      toast.warn('File size shouldn\'t be more than 2 megabytes')
    } else {
      const data: any = {
        data: {
          firstName,
          lastName,
          sex: gender,
          interestedIn,
          ISO: country,
          birthday,
        },
        file,
        id: userId,
        cb: () => handleChangePage(next),
      }

      setLoading(true)

      dispatch(updateUserInfo(data))
        .then(response => {
          if (getSocialEnv()) {
            dispatch(getMe({ socialId: response?.payload?.socialId, socialType: getCurrentEnv() }))
          } else {
            if (handleChangeAuthStatus) {
              handleChangeAuthStatus('login')

              toast.success('Успешная регистрация! Войдите в аккаунт')
            }
            // navigate('/auth')
            // dispatch(authLogin({
            //   data: {
            //     email: sessionStorage.getItem('login') || '',
            //     password: sessionStorage.getItem('password') || '',
            //   },
            //   cb: (token, user) => {
            //     connectToSocket(token, user, false)
            //   },
            // }))
          }
        })
    }
  };

  return (
    <div className='auth-form registration-form'>
      <div className='auth-form-body'>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormControl variant='outlined' className='auth-form-body-input'>
            <Controller
              control={control}
              name='country'
              rules={{
                required: {
                  value: true,
                  message: t('errors.required'),
                },
              }}
              render={({
                field: {
                  onChange, value,
                },
              }) => (
                <Select
                  className='auth-input'
                  labelId='demo-simple-select-label'
                  id='demo-simple-select'
                  value={value}
                  onChange={onChange}
                >
                  {
                    countries.map((item, index) => <MenuItem
                      key={index}
                      value={item.code}
                    >
                      {item.name}
                    </MenuItem>)
                  }
                </Select>
              )}
            />
          </FormControl>
          <FormControl variant='outlined' className='auth-form-body-input'>
            <Controller
              control={control}
              name='interestedIn'
              rules={{
                required: {
                  value: true,
                  message: t('errors.required'),
                },
              }}
              render={({
                field: {
                  onChange, value,
                },
              }) => (
                <Select
                  className='auth-input'
                  labelId='demo-simple-select-label'
                  id='demo-simple-select'
                  value={value}
                  onChange={onChange}
                >
                  <MenuItem value={UserInterested.male}>{t('registration.male')}</MenuItem>
                  <MenuItem value={UserInterested.female}>{t('registration.female')}</MenuItem>
                  {/* <MenuItem value={UserInterested.notMatter}>{t('registration.noMatter')}</MenuItem> */}
                </Select>
              )}
            />
          </FormControl>
          <Box
            sx={{
              width: '100%',
              marginBottom: '15px',
            }}
          >
            <input
              type='file'
              id='file-input'
              className='registration-form-file-input'
              ref={fileInputRef}
              accept='image/png, image/gif, image/jpeg'
            />
            <label
              htmlFor='file-input'
              className='registration-form-file-input-label'
            >
              {fileInputRef.current?.files[0] ? t('registration.fileSelected') : t('registration.choosePhoto')}
            </label>
          </Box>
          <AuthButton loading={loading}>
            {t('buttons.next')}
          </AuthButton>
        </form>
      </div>
    </div>
  )
}