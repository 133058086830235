/* eslint-disable no-useless-escape */
/* eslint-disable react/no-unescaped-entities */
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Controller } from 'react-hook-form'
import {
  IconButton, InputAdornment, OutlinedInput, TextField,
} from '@mui/material'
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { AuthButton } from '../../../../components/Authentication';
import AuthenticationHooks from '../../hooks/LoginHooks'
import '../../styles/style.scss'

interface props {
  handleChangeAuthStatus: (status: 'login' | 'reg' | 'reset') => void
}

export default function Login({
  handleChangeAuthStatus,
}: props) {
  const {
    control,
    errors,
    showPassword,
    loginStatus,
    handleClickShowPassword,
    handleMouseDownPassword,
    handleSubmit,
    onSubmit,
  } = AuthenticationHooks()

  const { t, i18n } = useTranslation()

  return (
    <div className='auth-login'>
      <h3 className='auth-title'>Sign in</h3>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          control={control}
          name='email'
          rules={{
            required: {
              value: true,
              message: t('errors.required'),
            },
            pattern: {
              value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
              message: t('errors.invalidEmail'),
            },
          }}
          render={({
            field: {
              onChange, value,
            },
          }) => (
            <TextField
              id='outlined-basic'
              placeholder='Johnsmith@gmail.com'
              className='auth-input'
              variant='outlined'
              helperText={errors?.email?.message || ''}
              error={!!errors.email}
              value={value}
              onChange={onChange}
            />
          )}
        />
        <Controller
          control={control}
          name='password'
          rules={{
            required: {
              value: true,
              message: t('errors.required'),
            },
          }}
          render={({
            field: {
              onChange, value,
            },
          }) => (
            <OutlinedInput
              className='signInPassword auth-input'
              id='outlined-adornment-password'
              type={showPassword ? 'text' : 'password'}
              onChange={onChange}
              value={value}
              endAdornment={(
                <InputAdornment position='end'>
                  <IconButton
                    aria-label='toggle password visibility'
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge='end'
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              )}
              placeholder={t('registration.enterPassword') || ''}
            />
          )}
        />
        <a
          className='auth-privacy-policy-btn'
          target='_blank'
          href={`https://truthdare.online/privacy-policy/${i18n.language.toLowerCase()}.pdf`}
          rel='noreferrer'
        >
          {t('registration.openPrivacyPolicy')}
        </a>
        <span className='auth-forgotPass' onClick={() => handleChangeAuthStatus('reset')}>Forgor password?</span>
        <AuthButton>{t('login.login')}</AuthButton>
        {loginStatus === 'failed' && (
          <div className='auth-error'>
            <span>{t('errors.loginFail')}</span>
          </div>
        )}
      </form>
    </div>
  )
}