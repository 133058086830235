import connectToSocket from '../../utils/socket/connectToSocket';
import imageUpload from '../../utils/upload/cloudinaryUpload';

export default function FacebookInit({ getUser, updateFBUserInfo }: { getUser: any, updateFBUserInfo: any }) {
  const { FBInstant } = window as any

  try {
    FBInstant.initializeAsync().then(() => {
      FBInstant.startGameAsync().then(() => {
        const playerName = FBInstant.player.getName();
        const photo = FBInstant.player.getPhoto();
        const locale = FBInstant.getLocale();
        const id = FBInstant.player.getID();

        getUser({ id }).then(async response => {
          const { user, token } = response.payload;
          if (!user.firstName) {
            // const avatar: any = ''
            const avatar: any = await imageUpload(photo) || ''

            updateFBUserInfo({
              firstName: playerName,
              lastName: '',
              ISO: user.ISO,
              gender: user.sex,
              interestedIn: user.interestedIn,
              birthday: user.birthday,
              avatar: avatar?.data?.public_id || avatar,
              interfaceLang: locale || 'EN',
              id: user.id,
              update: true,
              socialId: id,
            })
          } else {
            connectToSocket(token, user, true)
          }
        })
      })
    })
  } catch (error) {
    // console.log(error, 'error------------------');
  }
}