import { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { authLogin, getLoginStatus } from '../../../app/slices/usersSlice';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import connectToSocket from '../../../utils/socket/connectToSocket';

export default function AuthenticationHooks() {
  const dispatch = useAppDispatch();

  const navigate = useNavigate()

  const loginStatus = useAppSelector(getLoginStatus)

  const [showPassword, setShowPassword] = useState(false);

  const {
    handleSubmit, control, formState: { errors },
  } = useForm({
    defaultValues: {
      email: '',
      password: '',
    },
  });

  const rememberPasswordCheckbox = useRef<any>(null)

  const onSubmit = (data: any) => {
    dispatch(authLogin({
      data,
      cb: (token, user) => {
        navigate('/')
        connectToSocket(token, user, true)
      },
    }))
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  return {
    control,
    errors,
    showPassword,
    loginStatus,
    rememberPasswordCheckbox,
    handleClickShowPassword,
    handleMouseDownPassword,
    handleSubmit,
    onSubmit,
  }
}