export default function getUserGenderForVk(gender) {
  switch (+gender) {
    case 2:
      return 0
    case 1:
    case 0:
      return 1
    default:
      return 0
  }
}

export function getUserInterestedInForVk(gender) {
  switch (+gender) {
    case 1:
    case 0:
      return 0
    case 2:
      return 1
    default:
      break;
  }
}

export function getUserGenderForOk(gender: string) {
  switch (gender) {
    case 'male':
      return 0
    default:
      return 1
  }
}

export function getUserInterestedInForOk(gender) {
  switch (gender) {
    case 'female':
      return 0
    case 'male':
      return 1
    default:
      return 0
  }
}