import axios from 'axios';

export default async function imageUpload(file: any | null) {
  if (file) {
    const formData = await new FormData();
    await formData.append('file', file);
    await formData.append('upload_preset', 'love-game-images-upload');
    const image = await axios.post('https://api.cloudinary.com/v1_1/doo4q6xrk/image/upload', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return image;
  }

  return null
}