import { CircularProgress } from '@mui/material'

interface props {
  children: any,
  loading?: boolean
}

export default function AuthButton({
  children,
  loading,
}: props) {
  return (
    <button className={`auth-btn ${loading && 'disabled'}`} type='submit'>{loading ? <CircularProgress /> : children}</button>
  )
}