import i18n from 'i18next'
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import ru from './locales/ru/translation.json'
import en from './locales/en/translation.json'
import es from './locales/es/translation.json'
import fr from './locales/fr/translation.json'
import jp from './locales/jp/translation.json'
import kr from './locales/kr/translation.json'
import cn from './locales/cn/translation.json'

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(Backend)
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: false,
    fallbackLng: 'ru',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    lng: localStorage.getItem('i18nextLng')?.toLowerCase() || 'ru',
    resources: {
      ru,
      en,
      es,
      fr,
      jp,
      kr,
      cn,
    },

  });

export default i18n;