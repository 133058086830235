/* eslint-disable no-useless-escape */
import { useState } from 'react'
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import {
  FormControl, FormHelperText, TextField, Button, CircularProgress, InputLabel,
} from '@mui/material';
import { loadingStatus } from '../../../../interfaces/MainInterfaces';
import { sendCheckCode } from '../../../../app/slices/authSlice';
import { useAppDispatch } from '../../../../app/hooks';
import { AuthButton } from '../../../../components/Authentication';
import AuthFormHead from '../../../../components/Authentication/Layouts/AuthFormHead';

const styles = {
  textField: {
    backgroundColor: '#fff',
    width: '100%',
    outline: 'none',
    borderRadius: '5px',
  },
  input: {
    outline: 'none',
  },
}

export default function SendCodeToEmail({
  handleChangePage,
}: {
  handleChangePage: (page: number | null) => void
}) {
  const { t, i18n } = useTranslation()

  const dispatch = useAppDispatch()

  const {
    handleSubmit, control, formState: { errors },
  } = useForm({
    defaultValues: {
      email: '',
    },
  });

  const [loading, setLoading] = useState<loadingStatus>('loaded')

  const onSubmit = (data: any) => {
    if (data.email && loading !== 'loading') {
      setLoading('loading')

      sessionStorage.setItem('reset_pass_email', data.email)

      dispatch(sendCheckCode({
        ...data,
        interfaceLang: i18n.language.toUpperCase(),
      })).then(response => {
        if (response?.payload?.status < 300) {
          handleChangePage(1)
        } else {
          setLoading('loaded')
        }
      })
    }
  };

  return (
    <div className='auth-form'>
      <AuthFormHead
        title='Восстановление'
        text='Введите почту для восстановления'
      />
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormControl variant='outlined' className='auth-form-body-input'>
          <Controller
            control={control}
            name='email'
            rules={{
              required: {
                value: true,
                message: t('errors.required'),
              },
              pattern: {
                value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
                message: t('errors.invalidEmail'),
              },
            }}
            render={({
              field: {
                onChange, value,
              },
            }) => (
              <TextField
                inputProps={styles.input}
                id='registration-email'
                className='auth-input'
                placeholder={t('registration.email') || ''}
                variant='outlined'
                error={!!errors.email}
                value={value}
                onChange={onChange}
              />
            )}
          />
          {!!errors.email && (
            <FormHelperText error id='accountId-error'>
              {errors.email.message}
            </FormHelperText>
          )}
        </FormControl>
        <AuthButton loading={loading === 'loading'}>Запросить код</AuthButton>
      </form>
    </div>
  )
}