import {
  lazy,
  useCallback,
  useEffect,
  useMemo, useState,
} from 'react';
import {
  Route, Routes,
} from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import FBUpdateInfoBanner from './components/Banners/FBUpdateInfoBanner';
import getCurrentEnv, { getSocialEnv } from './utils/variables';
import { setWelcomeModalOpen } from './app/slices/modalsSlice';
import AuthenticationHooks from './hooks/AuthenticationHooks';
import { useAppDispatch, useAppSelector } from './app/hooks';
import Authentication from './features/Authentication/ui';
import { getUserInfo } from './app/slices/usersSlice';
import { MyGlobalContext } from './utils/context';
import SocketHooks from './hooks/SocketHooks';

import 'react-toastify/dist/ReactToastify.css';

import 'dayjs/locale/en'
import 'dayjs/locale/zh-cn'
import 'dayjs/locale/ko'
import 'dayjs/locale/fr'
import 'dayjs/locale/ru'
import 'dayjs/locale/es'
import 'dayjs/locale/ja'

const CreateUser = lazy(() => import('./components/CreateUser'))
const Main = lazy(() => import('./pages/Main'))

export default function App() {
  const socketHooks = SocketHooks();
  const authenticationHooks = AuthenticationHooks();

  const dispatch = useAppDispatch()

  const { i18n } = useTranslation()

  const initialColor = useMemo(() => (localStorage.getItem('theme_2') || 'blue'), [])
  const userInfo = useAppSelector(getUserInfo);

  const [theme, setTheme] = useState<any>(initialColor);

  const initialValue = useMemo(() => ({ theme, setTheme }), [theme]);

  const handleOpenWelcomeBanner = useCallback(() => {
    dispatch(setWelcomeModalOpen({ open: true }))
  }, [])

  useEffect(() => {
    window.addEventListener('first_login', handleOpenWelcomeBanner)

    if (getCurrentEnv() === 'vk' || getCurrentEnv() === 'ok' || getCurrentEnv() === 'ml') {
      i18n.changeLanguage('ru')
    }

    if (window.location.host !== 'localhost:3000') {
      navigator.mediaDevices
      ?.getUserMedia({ video: true, audio: true })
    }
  }, [])

  if (userInfo?.user && !userInfo?.user?.firstName && !userInfo?.user?.socialId) {
    if (!getSocialEnv()) {
      return <CreateUser />
    }
  }

  return (
    <>
      <ToastContainer
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        style={{ zIndex: 100000000 }}
      />
      <MyGlobalContext.Provider value={initialValue}>
        {/* eslint-disable-next-line no-nested-ternary */}
        <div className={`root-block ${theme}`}>
          <Routes>
            <Route
              path='/'
              element={
                <Main />
              }
            />
            <Route
              path='/auth'
              element={
                <Authentication />
              }
            />
            {/* <Route
              path='/registration'
              element={
                <Registration />
              }
            />
            <Route
              path='/reset-password'
              element={
                <ResetPassword />
              }
            /> */}
          </Routes>
          <FBUpdateInfoBanner />
        </div>
      </MyGlobalContext.Provider>
    </>
  )
}