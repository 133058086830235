/* eslint-disable no-useless-escape */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useCallback, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  Checkbox, FormHelperText, IconButton, InputAdornment, MenuItem, OutlinedInput,
  Select,
  TextField,
} from '@mui/material';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Visibility from '@mui/icons-material/Visibility';
import { authRegistration } from '../../../../app/slices/usersSlice';
import { AuthButton } from '../../../../components/Authentication';
import { useAppDispatch } from '../../../../app/hooks';
import CheckCode from './CheckCode';
import { languages } from '../../utils/countries';
import { getSocialEnv } from '../../../../utils/variables';

interface props {
  handleChangePage: (page: number) => void
}

export default function Registration({
  handleChangePage,
}: props) {
  const { t } = useTranslation()

  const dispatch = useAppDispatch()

  const [loading, setLoading] = useState<boolean>(false)
  const [activeStage, setActiveStage] = useState<number>(0)
  const [showPassword, setShowPassword] = useState(false);
  const [interfaceLang, setInterfaceLang] = useState<string>('EN')
  const [confirmPasswordError, setConfirmPasswordError] = useState(false)

  const {
    handleSubmit, control, formState: { errors }, getValues, watch,
  } = useForm({
    defaultValues: {
      password: '',
      email: '',
      interfaceLang: 'ru',
      confirm_password: '',
      privacy: false,
    },
  });

  const onSubmit = (data: any) => {
    if (data.password !== data.confirm_password) {
      setConfirmPasswordError(true)
    } else {
      setLoading(true)

      setConfirmPasswordError(false)
      sessionStorage.setItem('login', data.email)
      sessionStorage.setItem('password', data.password)

      dispatch(authRegistration({
        ...data,
        interfaceLang: interfaceLang.toUpperCase(),
        cb: () => {
          handleChangeActiveStage(1)
          setLoading(false)
          // dispatch(sendCheckCode({
          //   email: data.email,
          //   interfaceLang: interfaceLang.toUpperCase(),
          //   cb: () => {
          //   },
          // }))
        },
      })).then(response => {
        if (response.payload.statusCode === 400) {
          setLoading(false)
        }
      })
    }
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const handleChangeActiveStage = useCallback((stage: number) => {
    setActiveStage(stage)
  }, [])

  useEffect(() => {
    setInterfaceLang(getValues('interfaceLang'))
  }, [watch('interfaceLang')])

  return (
    <div className='auth-form'>
      {activeStage === 0 && <form onSubmit={handleSubmit(onSubmit)}>
        <h3 className='auth-title'>Sign in</h3>
        <Controller
          control={control}
          name='email'
          rules={{
            required: {
              value: true,
              message: t('errors.required'),
            },
            pattern: {
              value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
              message: t('errors.invalidEmail'),
            },
          }}
          render={({
            field: {
              onChange, value,
            },
          }) => (
            <TextField
              id='registration-email'
              placeholder={t('registration.email') || ''}
              className='auth-input'
              variant='outlined'
              error={!!errors.email}
              value={value}
              onChange={onChange}
            />
          )}
        />
        {!!errors.email && (
          <FormHelperText error id='accountId-error'>
            {errors.email.message}
          </FormHelperText>
        )}
        <Controller
          control={control}
          name='password'
          rules={{
            required: {
              value: true,
              message: t('errors.required'),
            },
            min: {
              value: 6,
              message: `${t('errors.minLength')} 6`,
            },
          }}
          render={({
            field: {
              onChange, value,
            },
          }) => (
            <OutlinedInput
              id='registration-password'
              type={showPassword ? 'text' : 'password'}
              className='auth-input'
              onChange={onChange}
              value={value}
              error={!!errors.password}
              endAdornment={(
                <InputAdornment position='end'>
                  <IconButton
                    aria-label='toggle password visibility'
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge='end'
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              )}
            />
          )}
        />
        {!!errors.password && (
          <FormHelperText error id='accountId-error'>
            {errors.password.message}
          </FormHelperText>
        )}
        <Controller
          control={control}
          name='confirm_password'
          rules={{
            required: {
              value: true,
              message: t('errors.required'),
            },
          }}
          render={({
            field: {
              onChange, value,
            },
          }) => (
            <OutlinedInput
              id='registration-confirm-password'
              type={showPassword ? 'text' : 'password'}
              className='auth-input'
              onChange={onChange}
              value={value}
            />
          )}
        />
        {confirmPasswordError && (
          <FormHelperText error id='accountId-error'>
            {t('registration.passwordsNotSame')}
          </FormHelperText>
        )}
        {!getSocialEnv()
          && <Controller
            control={control}
            name='interfaceLang'
            rules={{
              required: {
                value: true,
                message: t('errors.required'),
              },
            }}
            render={({
              field: {
                onChange, value,
              },
            }) => (
              <Select
                labelId='demo-simple-select-label'
                id='demo-simple-select'
                className='auth-input'
                value={value}
                onChange={onChange}
              >
                {
                  languages.filter(country => ['en', 'es', 'cn', 'fr', 'ru', 'ko', 'ja'].includes(country.code)).map((item, index) => <MenuItem
                    value={item.code}
                    key={index}
                  >
                    {item.name}
                  </MenuItem>)
                }
              </Select>
            )}
          />}
        <div className='auth-privacy-policy'>
          <div>
            <span className='auth-privacy-policy-topText'>
              {' '}
              {t('registration.privacyPolicyAgree')}
            </span>
            <Controller
              control={control}
              name='privacy'
              rules={{
                required: {
                  value: true,
                  message: t('errors.required'),
                },
              }}
              render={({
                field: {
                  onChange, value,
                },
              }) => (
                <Checkbox
                  value={value}
                  onChange={onChange}
                  style={{
                    color: '#fff',
                  }}
                />
              )}
            />
            {!!errors.privacy && (
              <FormHelperText error id='accountId-error'>
                {errors.privacy.message}
              </FormHelperText>
            )}
          </div>
          <a
            className='auth-privacy-policy-btn'
            target='_blank'
            href={`https://truthdare.online/privacy-policy/${interfaceLang.toLowerCase()}.pdf`}
            rel='noreferrer'
          >
            {t('registration.openPrivacyPolicy')}
          </a>
        </div>
        <AuthButton loading={loading}>{t('registration.signIn')}</AuthButton>
      </form>}
      {activeStage === 1 && <>
        <h3 className='auth-title'>Введите код</h3>
        <CheckCode
          handleChangePage={handleChangePage}
        />
      </>}
    </div>
  )
}