import API from '../../services/API';
import getAccessToken from './getAccesToken';

let usersList: any[] = [];
const usersIds: number[] = [];

export default async function getUsers(users: number[]) {
  const unknownUsersIds = users.filter(item => !usersIds.includes(item))

  if (unknownUsersIds.length > 0) {
    const response = await API.post('/user/byIds', {
      userIds: unknownUsersIds,
    })

    usersIds.push(...unknownUsersIds)
    usersList.push(...response.data.usersList)
  }

  return usersList.filter(item => users.includes(item.id))
}

export function getSingleUserInfo(id: any) {
  return usersList.find(item => item.id === id)
}

export async function updateUser(id: number) {
  const response = await API.get(`/user/getUser/${id}`, {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  })

  usersList = [
    ...usersList.filter(item => item.id !== id),
    response.data.user,
  ]
}