/* eslint-disable no-case-declarations */
/* eslint-disable no-lonely-if */
/* eslint-disable camelcase */
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import qs from 'querystringify'
import {
  acceptInvite, getMe, getUser, getUserMoney, updateUserInfo,
} from '../../app/slices/usersSlice';
import { VKUserInfo } from '../../interfaces/GameTableInterfaces';
import connectToSocket from '../../utils/socket/connectToSocket';
import { changeFbData } from '../../app/slices/gameInfoSlice';
import getCurrentEnv from '../../utils/variables';
import { useAppDispatch } from '../../app/hooks';
import FacebookInit from './FacebookInit';
import MailruInit from './MailruInit';
import SocialAuth from './SocialAuth';
import VKInit from './VKInit';
import OKInit from './OkInit';

export default function AuthenticationHooks() {
  const socialAuth = SocialAuth()

  const dispatch = useAppDispatch();
  const navigate = useNavigate()

  useEffect(() => {
    const { request_key }: any = qs.parse(window.location?.href)

    const appEnv = getCurrentEnv()

    if (request_key) {
      dispatch(acceptInvite(request_key))
    }

    switch (appEnv) {
      case 'vk':
        VKInit({
          getUser: (data: any) => dispatch(getMe({
            socialId: data?.id,
            socialType: 'vk',
          })),
          updateUser: (data: VKUserInfo, id: number) => dispatch(updateUserInfo({
            data, id,
          })),
        })
        break;
      case 'ok':
        OKInit({
          getUser: (data: { uid: any; }) => dispatch(getMe({
            socialId: data.uid,
            socialType: 'ok',
          })),
          updateUser: (data: any, id: number) => dispatch(updateUserInfo({
            data, id,
          })),
          updateUserMoney: () => dispatch(getUserMoney()),
        })
        break;
      case 'fb':
        FacebookInit({
          getUser: (data: any) => dispatch(getMe({
            socialId: data?.id,
            socialType: 'fb',
          })),
          updateFBUserInfo: (data) => dispatch(changeFbData(data)),
        })
        break;
      case 'ml':
        MailruInit({
          getUser: (data: { uid: any; }) => dispatch(getMe({
            socialId: data.uid,
            socialType: 'ml',
          })),
          updateUser: (data: any, id: number) => dispatch(updateUserInfo({
            data, id,
          })),
          updateUserMoney: () => dispatch(getUserMoney()),
        })
        break;

      default:
        if (!localStorage.getItem('access_token')) {
          navigate('/auth')
        } else {
          dispatch(getUser())
            .then((response: any) => {
              if (response.payload) {
                const { token, user } = response.payload
                localStorage.setItem('access_token', token)

                connectToSocket(token, user, true)

                navigate('/')
              } else {
                navigate('/auth')
              }
            })
        }
        break;
    }
  }, [])
}