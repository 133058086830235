import imageUpload from '../upload/cloudinaryUpload';

export default async function createDataForVkUser(data: any) {
  const avatar: any = await imageUpload(data.avatar) || ''

  const info = {
    ISO: 'RU',
    firstName: data?.first_name || '',
    lastName: data?.last_name || '',
    sex: 0,
    interestedIn: 1,
    birthday: null,
    avatar: avatar?.data.public_id,
    interfaceLang: 'RU',
  }

  return info
}

export async function createDataForOKUser(data: any) {
  const avatar: any = await imageUpload(data.pic_3) || ''

  const {
    birthday,
    gender,
    first_name: firstName,
    last_name: lastName,
    locale: ISO,
    location,
  } = data

  const info = {
    ISO: location?.countryCode || 'RU',
    firstName: firstName || '',
    lastName: lastName || '',
    sex: gender === 'male' ? 0 : 1,
    interestedIn: gender === 'male' ? 1 : 0,
    birthday: birthday || null,
    avatar: avatar?.data.public_id,
    interfaceLang: ISO || 'RU',
  }

  return info
}

export async function createDataForGoogleUser(data: any) {
  const avatar: any = await imageUpload(data.avatar) || ''

  const info = {
    ISO: data.interfaceLang.toUpperCase(),
    firstName: data?.first_name || '',
    lastName: data?.last_name || '',
    sex: 0,
    interestedIn: 1,
    birthday: null,
    avatar: avatar?.data.public_id,
    interfaceLang: data.interfaceLang.toUpperCase(),
  }

  return info
}

export async function createDataForFacebookUser(data: any) {
  const avatar: any = await imageUpload(data.avatar) || ''

  const info = {
    ISO: 'US',
    firstName: data?.firstName || '',
    lastName: data?.lastName || '',
    sex: 0,
    interestedIn: 1,
    birthday: null,
    avatar: avatar?.data.public_id,
    interfaceLang: 'EN',
  }

  return info
}

export function checkSearchParams(searchParams) {
  if (window.location.href.includes('google') || window.location.href.includes('ok')) {
    // Разбиваем URL на части
    return window.location.href.split('#')[1];
  }

  return searchParams
}

export function handleSocialLogOut() {
  const socialType = localStorage.getItem('socialType');
  const socialToken = localStorage.getItem('socialToken')

  if (socialType) {
    switch (socialType) {
      case 'google':
        fetch(`https://oauth2.googleapis.com/revoke?token=${socialToken}`, {
          method: 'POST',
          headers: {
            'Content-type': 'application/x-www-form-urlencoded',
          },
        })
        break;

      default:
        break
    }
  }
}