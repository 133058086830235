import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { loadingStatus } from '../../interfaces/MainInterfaces'
import audioItem, { playlistItem } from '../../interfaces/AudioInterfaces'
import AudioAPI from '../../services/AudioAPI'
import { RootState } from '../store'

const data = JSON.parse(localStorage.getItem('disable_audio_gift') || '{}')

export interface audioBarInfo {
  status?: 'play' | 'pause',
  currentAudio?: audioItem | null,
  isMuted?: boolean,
  audioRef?: HTMLAudioElement | null,
  roomAudioList?: audioItem[],
  myAudioList?: audioItem[],
  audioList?: audioItem[],
  disableGifts?: boolean,
  playlistType?: 'own' | 'room'
}

interface initState {
  musicList: {
    data: audioItem[],
    count: number
  } | null,
  playList: {
    playlist: playlistItem[],
    count: number
  } | null,
  audioPlayerBarInfo: audioBarInfo
  status: loadingStatus,
  audioEditStatus: loadingStatus,
  audioBarOpen: boolean,
  audioInstance: any,
  audioGiftInfo: {
    sendAudioGift: boolean,
    toAll: boolean,
    receiverId: number | null,
  }
}

const initialState: initState = {
  musicList: null,
  playList: null,
  audioPlayerBarInfo: {
    currentAudio: null,
    status: 'pause',
    isMuted: false,
    audioList: [],
    audioRef: null,
    disableGifts: data.disableGifts || false,
    roomAudioList: [],
    myAudioList: JSON.parse(localStorage.getItem('music_playlist') || '[]') || [],
    playlistType: 'room',
  },
  status: 'loaded',
  audioEditStatus: 'loaded',
  audioBarOpen: false,
  audioInstance: null,
  audioGiftInfo: {
    sendAudioGift: false,
    toAll: false,
    receiverId: null,
  },
}

export const getAudioList = createAsyncThunk(
  'audio/getAudioList',
  async (search: any) => {
    const response = await AudioAPI.get(`/music/get?${search}`)

    return response.data;
  },
)

export const getAudioListById = createAsyncThunk(
  'audio/getAudioListById',
  async (search: any) => {
    const response = await AudioAPI.get(`/music/playlist/my?${search}`)

    return response.data;
  },
)

export const uploadAudio = createAsyncThunk(
  'audio/uploadAudio',
  async (data: any) => {
    const response = await AudioAPI.post('/music/add', data);

    return response.data;
  },
)

export const editAudio = createAsyncThunk(
  'audio/editAudio',
  async (data: {
    id: number,
    title: string,
    cb: any
  }) => {
    const { id, title, cb } = data

    const response = await AudioAPI.put('/music/update', {
      id,
      title,
    });

    cb()

    return response.data;
  },
)

export const addToPlaylist = createAsyncThunk(
  'audio/addToPlaylist',
  async (data: any) => {
    const response = await AudioAPI.post('/music/playlist/my', {
      musicId: data.id,
    });

    return response.data;
  },
)

export const deleteFromPlaylist = createAsyncThunk(
  'audio/addToPlaylist',
  async (data: any) => {
    const response = await AudioAPI.delete(`/music/playlist/my/${data.id}`);

    data.cb()

    return response.data;
  },
)

const audio = createSlice({
  name: 'audio',
  initialState,
  reducers: {
    openAudioBar: (state) => {
      state.audioBarOpen = true
    },
    closeAudioBar: (state) => {
      state.audioBarOpen = false

      state.audioGiftInfo = {
        ...state.audioGiftInfo,
        sendAudioGift: false,
      }
    },
    setAudioBarInstance: (state, action) => {
      state.audioInstance = action.payload
    },
    changeAudioPlayerBarInfo: (state, action: PayloadAction<{
      status?: 'play' | 'pause',
      currentAudio?: audioItem | null,
      isMuted?: boolean,
      audioList?: audioItem[],
      myAudioList?: audioItem[],
      audioRef?: any,
      disableGifts?: boolean,
      playlistType?: 'own' | 'room'
    }>) => {
      state.audioPlayerBarInfo = {
        ...state.audioPlayerBarInfo,
        ...action.payload,
      }
    },
    changeAudioGiftInfo: (state, action: PayloadAction<{
      sendAudioGift: boolean,
      toAll: boolean,
      receiverId: number
    }>) => {
      state.audioGiftInfo = {
        ...state.audioGiftInfo,
        ...action.payload,
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAudioListById.pending, (state) => {
      state.status = 'loading'
    })
    builder.addCase(getAudioListById.fulfilled, (state, action) => {
      state.status = 'loaded'

      state.playList = {
        ...action.payload,
      }

      state.musicList = {
        data: state.musicList?.data || [],
        count: action.payload.count,
      }
    })
    builder.addCase(getAudioList.pending, (state) => {
      state.status = 'loading'
    })
    builder.addCase(getAudioList.fulfilled, (state, action) => {
      state.musicList = {
        ...action.payload,
      }
      state.status = 'loaded'
    })

    builder.addCase(editAudio.pending, (state) => {
      state.audioEditStatus = 'loading'
    })
    builder.addCase(editAudio.fulfilled, (state) => {
      state.audioEditStatus = 'loaded'
    })
    builder.addCase(editAudio.rejected, (state) => {
      state.audioEditStatus = 'loaded'
    })
  },
})

export const {
  openAudioBar,
  closeAudioBar,
  changeAudioGiftInfo,
  setAudioBarInstance,
  changeAudioPlayerBarInfo,
} = audio.actions

export const getAudioPlayBarInfo = (state: RootState) => state.audio.audioPlayerBarInfo
export const getAudioEditInfo = (state: RootState) => state.audio.audioEditStatus
export const getAudioGiftInfo = (state: RootState) => state.audio.audioGiftInfo
export const getAudioListStatusInfo = (state: RootState) => state.audio.status
export const getAudioBarInfo = (state: RootState) => state.audio.audioBarOpen
export const getAudioListInfo = (state: RootState) => state.audio.musicList
export const getPlaylistInfo = (state: RootState) => state.audio.playList

export default audio.reducer