import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { RootState } from '../store'

export enum TutorialTypes {
  START,
  QUESTION,
  GIFT,
  QUESTIONGAME,
  MATCHGAME,
  CHOOSEGAME,
  ACTIONGAME,
  KARAOKEGAME,
  CHOOSEFAVORITE,
  LOTO,
  LEVELUPMODAL,
  LEVELUPBUY,
  INVITESEND,
  ACCEPTINVITE,
  CLANSMODAL,
  ROOMSMODAL,
  ENDDIALOG,
  END,
}

interface initState {
  tutorialPage: TutorialTypes,
  tutorialActive: boolean,
}

const initialState: initState = {
  tutorialPage: TutorialTypes.START,
  tutorialActive: false,
}

const tutorial = createSlice({
  name: 'tutorial',
  initialState,
  reducers: {
    setActiveTutorial: (state) => {
      state.tutorialActive = true
    },
    setDisabledTutorial: (state) => {
      state.tutorialActive = false
    },
    nextTutorialPage: (state) => {
      state.tutorialPage += 1
    },
    resetTutorialPage: (state) => {
      state.tutorialPage = TutorialTypes.START
    },
  },
  extraReducers: (builder) => {

  },
})

export const {
  setActiveTutorial, setDisabledTutorial, nextTutorialPage, resetTutorialPage,
} = tutorial.actions

export const getCurrentTutorialPage = (state: RootState) => state.tutorial.tutorialPage;
export const getTutorialStatus = (state: RootState) => state.tutorial.tutorialActive;

export default tutorial.reducer