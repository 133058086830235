/* eslint-disable default-param-last */
/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */

import { v4 as uuid } from 'uuid'

// import rank1 from '../../assets/images/ranks/1.png'
// import rank2 from '../../assets/images/ranks/2.png'
// import rank3 from '../../assets/images/ranks/3.png'
// import rank4 from '../../assets/images/ranks/4.png'
// import rank5 from '../../assets/images/ranks/5.png'
// import rank6 from '../../assets/images/ranks/6.png'
// import rank7 from '../../assets/images/ranks/7.png'
// import rank8 from '../../assets/images/ranks/8.png'
// import rank9 from '../../assets/images/ranks/9.png'
// import rank10 from '../../assets/images/ranks/10.png'
// import rank11 from '../../assets/images/ranks/11.png'
// import rank12 from '../../assets/images/ranks/12.png'

/* eslint-disable prefer-destructuring */
const ranksForBoys = {
  en: ['No title', 'Knight', 'Nobleman', 'Baron', 'Viscount', 'Count', 'Marquis', 'Duke', 'Prince', 'King', 'Emperor', 'Caesar', 'Lord of the World'],
  ru: ['Нет титула', 'Рыцарь', 'Дворянин', 'Барон', 'Виконт', 'Граф', 'Маркиз', 'Герцог', 'Принц', 'Король', 'Император', 'Цезарь', 'Владика мира'],
  fr: ['Pas de titre', 'Chevalier', 'Noble', 'Baron', 'Vicomte', 'Comte', 'Marquis', 'Duc', 'Prince', 'Roi', 'Empereur', 'César', 'Seigneur du monde'],
  kr: ['제목 없음', '기사', '귀족', '바론', '비콘트', '그래프', '마르키스', '듀크', '왕자', '왕', '황제', '카이사르', '세계의 주님'],
  cn: ['没有头衔', '骑士', '贵族', '男爵', '子爵', '图表', '侯爵', '公爵', '王子', '国王', '皇帝', '凯撒', '世界之主'],
  es: ['Sin título', 'Caballero', 'Noble', 'Barón', 'Vizconde', 'Conde', 'Marqués', 'Duque', 'Príncipe', 'Rey', 'Emperador', 'César', 'Señor del mundo'],
  jp: ['タイトルなし', 'ナイト', 'ノーブルマン', 'バロン', '子爵', 'グラフ', '侯爵', 'デューク', 'プリンス', 'キング', 'エンペラー', 'シーザー', '世界の主'],
}

const ranksForGirls = {
  en: ['No title', 'Knight', 'Nobleman', 'Baron', 'Viscount', 'Count', 'Marquis', 'Duke', 'Princess', 'Queen', 'Emperor', 'Caesar', 'Lord of the World'],
  ru: ['Нет титула', 'Рыцарь', 'Дворянин', 'Барон', 'Виконт', 'Граф', 'Маркиз', 'Герцог', 'Принцесса', 'Королева', 'Император', 'Цезарь', 'Владика мира'],
  fr: ['Pas de titre', 'Chevalier', 'Noble', 'Baron', 'Vicomte', 'Comte', 'Marquis', 'Duc', 'Princesse', 'Reine', 'Empereur', 'César', 'Seigneur du monde'],
  kr: ['제목 없음', '기사', '귀족', '바론', '비콘트', '그래프', '마르키스', '듀크', '공주', '여왕', '황제', '카이사르', '세계의 주님'],
  cn: ['没有头衔', '骑士', '贵族', '男爵', '子爵', '图表', '侯爵', '公爵', '公主', '女王', '皇帝', '凯撒', '世界之主'],
  es: ['Sin título', 'Caballero', 'Noble', 'Barón', 'Vizconde', 'Conde', 'Marqués', 'Duque', 'Princesa', 'Reina', 'Emperador', 'César', 'Señor del mundo'],
  jp: ['タイトルなし', 'ナイト', 'ノーブルマン', 'バロン', '子爵', 'グラフ', '侯爵', 'デューク', 'プリンセス', 'クイーン', 'エンペラー', 'シーザー', '世界の主'],
}

const ranksNumbers = {
  30: '|',
  40: '||',
  50: '|||',
  60: '|',
  80: '||',
  100: '|',
  120: '||',
  135: '|||',
  150: '|',
  200: '|',
  220: '||',
  235: '|||',
  250: '|',
  270: '||',
  285: '|||',
  300: '|',
  330: '||',
  370: '|||',
  400: '|',
  430: '||',
  470: '|||',
  500: '|',
  575: '||',
  650: '|||',
  700: '|',
  800: '||',
  900: '|||',
  1000: '',
  1500: '',
}

export default function getUserFullName(user) {
  if (user?.firstName) {
    return `${user?.firstName || ''} ${user?.lastName || ''}`
  }

  return null
}

export function cutString(str: string | undefined, count: number) {
  if (!str || !str.substring) {
    return null
  }

  if (str.length > count) {
    return `${str.substring(0, count - 2)}...`
  }

  return str
}

export function getUserRank(level, lang: string, user: any) {
  const ranksForCurrentLang = user.sex === 0 ? ranksForBoys[lang || 'en'] : ranksForGirls[lang || 'en']

  let rank = ranksForCurrentLang[0]
  let rankNumber = ''

  if (level) {
    if (level < 30) {
      rank = ranksForCurrentLang[0]
    } else if (level < 60) {
      rank = ranksForCurrentLang[1]
    } else if (level < 100) {
      rank = ranksForCurrentLang[2]
    } else if (level < 150) {
      rank = ranksForCurrentLang[3]
    } else if (level < 200) {
      rank = ranksForCurrentLang[4]
    } else if (level < 250) {
      rank = ranksForCurrentLang[5]
    } else if (level < 300) {
      rank = ranksForCurrentLang[6]
    } else if (level < 400) {
      rank = ranksForCurrentLang[7]
    } else if (level < 500) {
      rank = ranksForCurrentLang[8]
    } else if (level < 700) {
      rank = ranksForCurrentLang[9]
    } else if (level < 1000) {
      rank = ranksForCurrentLang[10]
    } else if (level < 1500) {
      rank = ranksForCurrentLang[11]
    } else {
      rank = ranksForCurrentLang[12]
    }
  }

  for (const key in ranksNumbers) {
    if (level > key) {
      rankNumber = ranksNumbers[key]
    } else {
      break;
    }
  }

  return {
    rankText: `${rank} ${rankNumber}`,
  }
}












export const botsIds = [
  163842, 163882, 163885, 163887,
  163892, 163894, 163907, 163911,
  163913, 163914, 163915, 163916,
  163917, 163919, 163920, 163921,
  163938, 163939, 163941, 163943,
  164284, 164285, 164287, 164288,
  164289, 164290, 164292, 79409, 163844, 163847, 163848,
  163875, 163880, 163890, 163891,
  163898, 163900, 163904, 163905,
  163906, 163923, 163924, 163925,
  163929, 163930, 163932, 163934,
  163936, 163937, 163948, 164279,
  164282, 164283,
]




export const getDeviceId = () => {
  let deviceId = localStorage.getItem('DEVICE_ID_KEY') || ''
  if (!deviceId) {
    deviceId = uuid()
    localStorage.setItem('DEVICE_ID_KEY', deviceId)
  }
  return deviceId
}