interface props {
  title: string,
  text: string,
  children?: any
}

export default function AuthFormHead({
  title,
  text,
  children,
}: props) {
  return (
    <div className='auth-form-header'>
      <h3 className='auth-form-header-title title'>{title}</h3>
      <span className='auth-form-header-text' dangerouslySetInnerHTML={{ __html: text }} />
      {children}
    </div>
  )
}