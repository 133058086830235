import { Config, Connect } from '@vkontakte/superappkit';

const appId = process.env.REACT_APP_VKID_APPID ? +process.env.REACT_APP_VKID_APPID : 1;

Config.init({
  appId,
});

const VKSocialLogin = () => {
  Connect.redirectAuth({
    url: 'https://truthdare.online/',
    state: 'vk',
  });
}

export const GoogleSocialLogin = () => {
  window.location.href = 'https://accounts.google.com/o/oauth2/v2/auth?client_id=244613335773-qb83ei3j251bmls45nfljd4q2sm1u5du.apps.googleusercontent.com&redirect_uri=https://truthdare.online&response_type=token&scope=https://www.googleapis.com/auth/userinfo.profile&state=google&include_granted_scopes=true'
}

export const OKSocialLogin = () => {
  window.location.href = 'https://connect.ok.ru/oauth/authorize?client_id=512001893499&scope=VALUABLE_ACCESS&response_type=token&redirect_uri=https://truthdare.online&state=ok'
}

export default VKSocialLogin