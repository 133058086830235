import keys from '../../services/keys';

type bunnyFolders = 'chat_photo' | 'clans'

function bunnyUpload({
  data,
  folder,
}: {
  data: any,
  folder: bunnyFolders
}) {
  const file = data;

  const fileName = `${Date.now()}-${file.name}`;

  const myHeaders = new Headers();
  myHeaders.append('AccessKey', '0aae19a6-4d0c-492d-af51ea88ea4e-cad2-4b6d');

  const requestOptions: any = {
    method: 'PUT',
    headers: myHeaders,
    body: new Blob([file], {
      type: file.type,
    }),
    redirect: 'follow',
  };


  return fetch(`https://storage.bunnycdn.com/truth-dare-music/${folder}/${fileName}`, requestOptions)
    .then(() => ({
      fileName,
    }));
}

export async function clansIconsUpload(file: any | null) {
  const fileName = `${Date.now()}-${file.name}`;

  const myHeaders = new Headers();
  myHeaders.append('AccessKey', '0aae19a6-4d0c-492d-af51ea88ea4e-cad2-4b6d');

  const requestOptions: any = {
    method: 'PUT',
    headers: myHeaders,
    body: new Blob([file], {
      type: file.type,
    }),
    redirect: 'follow',
  };


  await fetch(`${keys.BUNNY_BASE_URL}/${keys.STORAGE_ZONE_NAME}/clans/${fileName}`, requestOptions)

  return fileName
}

export async function groupChatIconsUpload(file: any | null) {
  const fileName = `${Date.now()}-${file.name}`;

  const myHeaders = new Headers();
  myHeaders.append('AccessKey', '0aae19a6-4d0c-492d-af51ea88ea4e-cad2-4b6d');

  const requestOptions: any = {
    method: 'PUT',
    headers: myHeaders,
    body: new Blob([file], {
      type: file.type,
    }),
    redirect: 'follow',
  };

  await fetch(`${keys.BUNNY_BASE_URL}/${keys.STORAGE_ZONE_NAME}/group_chat_photos/${fileName}`, requestOptions)

  return fileName
}

export async function bunnyImageDelete({
  fileName,
  folder,
}: {
  fileName: string,
  folder: string
}) {
  if (fileName) {
    const myHeaders = new Headers();
    myHeaders.append('AccessKey', '0aae19a6-4d0c-492d-af51ea88ea4e-cad2-4b6d');

    const requestOptions: any = {
      method: 'DELETE',
      headers: myHeaders,
      redirect: 'follow',
    };

    await fetch(`${keys.BUNNY_BASE_URL}/${keys.STORAGE_ZONE_NAME}/${folder}/${fileName}`, requestOptions)

    return fileName
  }
}

export default function uploadChatPhoto(data) {
  return bunnyUpload({
    data,
    folder: 'chat_photo',
  })
}

export function uploadClanPhoto(data) {
  return bunnyUpload({
    data,
    folder: 'clans',
  })
}

export function deleteClanPhoto(fileName) {
  bunnyImageDelete({
    fileName,
    folder: 'clans',
  })
}