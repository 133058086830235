/* eslint-disable no-useless-escape */
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import { FormHelperText, TextField } from '@mui/material';
import { useState } from 'react';
import { useAppDispatch } from '../../../../app/hooks';
import { checkCode } from '../../../../app/slices/authSlice';
import { AuthButton } from '../../../../components/Authentication';

interface props {
  handleChangePage: (page: number) => void
}

export default function CheckCode({
  handleChangePage,
}: props) {
  const { t } = useTranslation()

  const {
    handleSubmit, control, formState: { errors },
  } = useForm({
    defaultValues: {
      code: '',
    },
  });

  const dispatch = useAppDispatch()

  const [loading, setLoading] = useState<boolean>(false)

  const onSubmit = (data: any) => {
    // if (data.password !== data.confirm_password) {
    //   setConfirmPasswordError(true)
    // } else {
    //   setConfirmPasswordError(false)
    //   sessionStorage.setItem('login', data.email)
    //   sessionStorage.setItem('password', data.password)

    //   dispatch(authRegistration({
    //     ...data,
    //     interfaceLang: data.interfaceLang.toUpperCase(),
    //     cb: () => handleChangePage.call(null, 1),
    //   }))
    // }
    const email = sessionStorage.getItem('login')

    setLoading(true)

    dispatch(checkCode({
      ...data,
      email,
      cb: () => {
        handleChangePage(1)
      },
    })).then(() => setLoading(false))
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Controller
        control={control}
        name='code'
        rules={{
          required: {
            value: true,
            message: t('errors.required'),
          },
        }}
        render={({
          field: {
            onChange, value,
          },
        }) => (
          <TextField
            id='registration-code'
            placeholder='Код'
            className='auth-input'
            variant='outlined'
            error={!!errors.code}
            value={value}
            onChange={onChange}
          />
        )}
      />
      {!!errors.code && (
        <FormHelperText error id='accountId-error'>
          {errors.code.message}
        </FormHelperText>
      )}
      <AuthButton loading={loading}>{t('registration.signIn')}</AuthButton>

    </form>
  )
}