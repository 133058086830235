const usersList = [
  {
    id: 0,
    firstName: 'John',
    birthday: '2000-12-05',
    crazyPoint: 5000,
    crazyLevel: 10,
    point: 70000,
    level: 40,
    ISO: 'US',
    sex: 0,
    interestedIn: 1,
    side: 'bottom',
    avatar: 'https://res.cloudinary.com/doo4q6xrk/image/upload/v1709191413/botsImages/user-avatar3_yunpzj.jpg',
  },
  {
    id: 1,
    firstName: 'Oliver',
    birthday: '2000-12-05',
    crazyPoint: 5000,
    crazyLevel: 10,
    point: 70000,
    level: 40,
    ISO: 'US',
    sex: 0,
    interestedIn: 1,
    side: 'bottom',
    avatar: 'https://res.cloudinary.com/doo4q6xrk/image/upload/v1709191412/botsImages/user-avatar2_ubyphh.jpg',
  },
  {
    id: 2,
    firstName: 'Jack',
    birthday: '2000-12-05',
    crazyPoint: 5000,
    crazyLevel: 10,
    point: 70000,
    level: 40,
    ISO: 'US',
    side: 'bottom',
    avatar: 'https://res.cloudinary.com/doo4q6xrk/image/upload/v1709191411/botsImages/user-avatar1_djyugv.jpg',
  },
  {
    id: 3,
    firstName: 'Harry',
    birthday: '2000-12-05',
    crazyPoint: 5000,
    crazyLevel: 10,
    point: 70000,
    level: 40,
    ISO: 'US',
    sex: 0,
    interestedIn: 1,
    side: 'bottom',
    avatar: 'https://res.cloudinary.com/doo4q6xrk/image/upload/v1709191404/botsImages/user-avatar6_daahyh.jpg',
  },
  {
    id: 4,
    firstName: 'James',
    birthday: '2000-12-05',
    crazyPoint: 5000,
    crazyLevel: 10,
    point: 70000,
    level: 40,
    ISO: 'US',
    sex: 0,
    interestedIn: 1,
    side: 'bottom',
    avatar: 'https://res.cloudinary.com/doo4q6xrk/image/upload/v1709191403/botsImages/user-avatar5_rje1u4.jpg',
  },
  {
    id: 5,
    firstName: 'Robert',
    birthday: '2000-12-05',
    crazyPoint: 5000,
    crazyLevel: 10,
    point: 70000,
    level: 40,
    ISO: 'US',
    sex: 0,
    interestedIn: 1,
    side: 'bottom',
    avatar: 'https://res.cloudinary.com/doo4q6xrk/image/upload/v1709191402/botsImages/user-avatar4_nnk4ep.jpg',
  },
  {
    id: 6,
    firstName: 'Amelia',
    birthday: '2000-12-05',
    crazyPoint: 5000,
    crazyLevel: 10,
    point: 70000,
    level: 40,
    ISO: 'US',
    sex: 1,
    interestedIn: 0,
    side: 'top',
    avatar: 'https://res.cloudinary.com/doo4q6xrk/image/upload/v1709191410/botsImages/user-avatar12_a8dzrg.jpg',
  },
  {
    id: 7,
    firstName: 'Olivia',
    birthday: '2000-12-05',
    crazyPoint: 5000,
    crazyLevel: 10,
    point: 70000,
    level: 40,
    ISO: 'US',
    sex: 1,
    interestedIn: 0,
    side: 'top',
    avatar: 'https://res.cloudinary.com/doo4q6xrk/image/upload/v1709191409/botsImages/user-avatar11_jlijop.jpg',
  },
  {
    id: 8,
    firstName: 'Margaret',
    birthday: '2000-12-05',
    crazyPoint: 5000,
    crazyLevel: 10,
    point: 70000,
    level: 40,
    ISO: 'US',
    sex: 1,
    interestedIn: 0,
    side: 'top',
    avatar: 'https://res.cloudinary.com/doo4q6xrk/image/upload/v1709191408/botsImages/user-avatar10_ju8ibr.jpg',
  },
  {
    id: 9,
    firstName: 'Emma',
    birthday: '2000-12-05',
    crazyPoint: 5000,
    crazyLevel: 10,
    point: 70000,
    level: 40,
    ISO: 'US',
    sex: 1,
    interestedIn: 0,
    side: 'top',
    avatar: 'https://res.cloudinary.com/doo4q6xrk/image/upload/v1709191407/botsImages/user-avatar9_gugxsr.jpg',
  },
  {
    id: 10,
    firstName: 'Samantha',
    birthday: '2000-12-05',
    crazyPoint: 5000,
    crazyLevel: 10,
    point: 70000,
    level: 40,
    ISO: 'US',
    sex: 1,
    interestedIn: 0,
    side: 'top',
    avatar: 'https://res.cloudinary.com/doo4q6xrk/image/upload/v1709191406/botsImages/user-avatar8_qkkzoc.jpg',
  },
  {
    id: 11,
    firstName: 'Jennifer',
    birthday: '2000-12-05',
    crazyPoint: 5000,
    crazyLevel: 10,
    point: 70000,
    level: 40,
    ISO: 'US',
    sex: 1,
    interestedIn: 0,
    side: 'top',
    avatar: 'https://res.cloudinary.com/doo4q6xrk/image/upload/v1709191405/botsImages/user-avatar7_dlsb3j.jpg',
  },
]

export default usersList