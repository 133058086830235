import axios from 'axios';
import keys from './keys';
import getAccessToken from '../utils/users/getAccesToken';

const AudioAPI = axios.create({
  baseURL: keys.AUDIO_SERVER_URL,
  responseType: 'json',
  timeout: 10000,
});

AudioAPI.interceptors.request.use(
  req => {
    req.headers.Authorization = `Bearer ${getAccessToken()}`

    return req
  },
)

export default AudioAPI;