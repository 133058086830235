import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import loadingCacheCleanBtnIcon from '../../assets/images/loading-cache-clean-btn.jpg'
import loadingCacheCleanIcon from '../../assets/images/loading-cache-clean.jpg'
import LoadingSvg from '../../utils/svgs/loadingSvg';
import getCurrentEnv from '../../utils/variables';
import './style.scss'

import loadingIcon from '../../assets/images/output-onlinegiftools2.gif'

export default function Loading() {
  const { t } = useTranslation()

  const [showClearText, setShowClearText] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      setShowClearText(true)
    }, 5000);
  }, [])

  return (
    <div className='main-loading'>
      {/* <LoadingSvg /> */}
      <img src='https://truthdare.online/assets/images/output-onlinegiftools2.gif' alt='loadingIcon' />
      {/* <img src={loadingIcon} alt='loadingIcon' /> */}
      {/* {showClearText && <>
        <span>{t('loadingText')}</span>
        {
          getCurrentEnv() === 'vk' && <>
            <img src={loadingCacheCleanIcon} alt='loadingCacheCleanIcon' />
            <img src={loadingCacheCleanBtnIcon} alt='loadingCacheCleanBtnIcon' />
          </>
        }
      </>} */}
    </div>
  )
}