import {
  useCallback, useEffect, useState,
} from 'react';
import _ from 'lodash';
import getUsers, { updateUser } from '../../../utils/users/getUsers';
import getCurrentEnv from '../../../utils/variables';
import socket from '../../../utils/socket/socket';
import {
  changeRoomMembers,
  getUserInfo,
  getUserMoney,
} from '../../../app/slices/usersSlice';
import i18n from '../../../i18n';
import {
  useAppDispatch, useAppSelector,
} from '../../../app/hooks';

export default function GameSocket() {
  const dispatch = useAppDispatch();

  const userInfo = useAppSelector(getUserInfo);

  const [updatedUser, setUpdatedUser] = useState<number>()
  const [update, setUpdate] = useState<number>(Math.random())
  const [roomMembers, setRoomMembers] = useState<{
    top: number[],
    bottom: number[]
  }>({
    top: [],
    bottom: [],
  })

  const handleSetRoomInfo = useCallback(async (data: any, updateId?: any) => {
    const topMembersIds = data.top.map((item: any) => item.userId);
    const bottomMembersIds = data.bottom.map((item: any) => item.userId);

    if (updateId) {
      await updateUser(+updateId)
    }

    const users = await getUsers([...topMembersIds, ...bottomMembersIds])

    setRoomMembers(data)

    if (data.top.length > 0 || data.bottom.length > 0) {
      const top = data.top.map((item: { userId: any; }) => {
        const user = { ...users.find((user) => user.id === item.userId) };

        user.side = 'top'
        return user
      });

      const bottom = data.bottom.map((item: { userId: any; }) => {
        const user = { ...users.find((user) => user.id === item.userId) };

        user.side = 'bottom'
        return user
      });

      const usersList = [...top, ...bottom]

      dispatch(changeRoomMembers(usersList))
    }
  }, [userInfo, roomMembers]);

  useEffect(() => {
    if (getCurrentEnv() !== 'vk' && getCurrentEnv() !== 'ok') {
      i18n.changeLanguage(userInfo?.user?.interfaceLang?.toLowerCase())
    }
  }, [userInfo]);

  useEffect(() => {
    if (roomMembers && updatedUser) {
      if (updatedUser === userInfo?.user?.id) {
        dispatch(getUserMoney())
      }
      handleSetRoomInfo({ ...roomMembers }, updatedUser)
    }
  }, [userInfo, updatedUser, update])

  useEffect(() => {
    socket.on('members', handleSetRoomInfo);

    return () => {
      socket.off('members', handleSetRoomInfo);
    }
  }, [])

  useEffect(() => {
    socket.on('user_change', data => setUpdatedUser(data.id))
    socket.on('user_change', () => setUpdate(Math.random()))
  }, [])
}