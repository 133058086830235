/* eslint-disable camelcase */
/* eslint-disable no-case-declarations */
import { useCallback, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import {
  getMe, getMeData, updateUserInfo,
} from '../../app/slices/usersSlice';
import createDataForVkUser, {
  checkSearchParams, createDataForFacebookUser, createDataForGoogleUser, createDataForOKUser,
} from '../../utils/auth/socialAuth';
import { getSocialAuthData, saveSocialAuthData } from '../../app/slices/authSlice';
import connectToSocket from '../../utils/socket/connectToSocket';

export default function SocialAuth() {
  const dispatch = useAppDispatch()

  const navigate = useNavigate()

  const [searchParams] = useSearchParams();

  const { user, socialType } = useAppSelector(getSocialAuthData)

  const handleGetMe = useCallback((data: getMeData) => dispatch(getMe(data)).then((res) => {
    const userInfo = res.payload

    if (userInfo?.user?.firstName) {
      connectToSocket(userInfo?.token, user, true)
    }

    return {
      userInfo,
    }
  }), [])

  const handleSocialLogin = useCallback(() => {
    handleGetMe({
      socialId: user.id,
      socialType,
    }).then((res: any) => {
      const { userInfo } = res;

      navigate('/')

      if (!userInfo.user.firstName) {
        handleUpdateUserInfo(user, userInfo?.user.id)?.then(() => {
          handleGetMe({
            socialId: user.id,
            socialType,
          })
        })
      }
    })
  }, [user, socialType])

  const handleUpdateUserInfo = useCallback(async (data, id) => {
    let info;

    localStorage.setItem('socialType', socialType)

    switch (socialType) {
      case 'vk':
        info = await createDataForVkUser(data)

        return dispatch(updateUserInfo({
          data: info, id,
        }))
      case 'ok':
        info = await createDataForOKUser(data)

        return dispatch(updateUserInfo({
          data: info, id,
        }))
      case 'google':
        info = await createDataForGoogleUser(data)

        return dispatch(updateUserInfo({
          data: info, id,
        }))
      case 'fb':
        info = await createDataForFacebookUser(data)

        return dispatch(updateUserInfo({
          data: info, id,
        }))

      default:
        break;
    }
  }, [socialType])

  const handleSaveSocialData = useCallback(async () => {
    const params = new URLSearchParams(checkSearchParams(searchParams));

    const state = params.get('state') || '';

    let data = {}

    let socialToken;

    if (state) {
      switch (state) {
        case 'vk':
          const payload = params.get('payload') || '';
          const { user } = JSON.parse(payload);

          data = {
            socialType: state,
            user,
          }
          break
        case 'ok':
          const okToken = params.get('access_token') || '';
          socialToken = okToken

          await fetch(`https://api.ok.ru/fb.do?method=users.getCurrentUser&application_key=CMHFOBLGDIHBABABA&access_token=${okToken}&sig=user`)
            .then(response => response.json())
            .then(((response: any) => {
              data = {
                socialType: state,
                user: {
                  ...response,
                  id: response.uid,
                },
              }
            }))
          break
        case 'google':
          const token = params.get('access_token')
          socialToken = token
          await fetch('https://www.googleapis.com/oauth2/v3/userinfo', {
            method: 'GET',
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
            .then(response => response.json())
            .then(((response: any) => {
              const {
                given_name: first_name,
                family_name: last_name,
                picture: avatar,
                locale: interfaceLang,
                sub: id,
              } = response

              data = {
                socialType: state,
                user: {
                  first_name,
                  last_name,
                  avatar,
                  interfaceLang,
                  id,
                },
              }
            }))
          break
        default:
      }

      localStorage.setItem('socialToken', socialToken)

      dispatch(saveSocialAuthData(data))
    }
  }, [searchParams])

  useEffect(() => {
    if (searchParams) {
      handleSaveSocialData()
    }
  }, [searchParams])

  useEffect(() => {
    if (socialType) {
      handleSocialLogin()
    }
  }, [user])
}