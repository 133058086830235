import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Button } from '@mui/material'
import AuthFormHead from '../../../../components/Authentication/Layouts/AuthFormHead'
import RegistrationPart1 from './RegistrationPart1'
import RegistrationPart2 from './RegistrationPart2'
import Registration from './Registration'
import '../../styles/style.scss'

interface props {
  handleChangeAuthStatus: (status: 'login' | 'reg' | 'reset') => void
}

export default function RegistrationWrapper({
  handleChangeAuthStatus,
}: props) {
  const { t } = useTranslation()

  const [activePage, setActivePage] = useState<number | null>(0)

  const handleChangePage = useCallback((page: number | null) => {
    setActivePage(page)
  }, [])

  return (
    <>
      {activePage === 0 && (
        <div className='animate__animated animate__backInRight'>
          <Registration handleChangePage={handleChangePage} />
        </div>
      )}
      {activePage === 1 && (
        <div className='animate__animated animate__backInRight'>
          <RegistrationPart1 next={2} handleChangePage={handleChangePage} />
        </div>
      )}
      {activePage === 2 && (
        <div className='animate__animated animate__backInRight'>
          <RegistrationPart2
            handleChangeAuthStatus={handleChangeAuthStatus}
            next={3}
            handleChangePage={handleChangePage}
          />
        </div>
      )}
      {activePage === 3 && (
        <div className='auth-success animate__animated animate__backInRight'>
          <AuthFormHead title={t('registration.congrats')} text={t('registration.successSignUp')}>
            <Link className='auth-link' to='/'>
              <Button variant='contained'>{t('registration.goToGame')}</Button>
            </Link>
          </AuthFormHead>
        </div>
      )}
    </>
  )
}