import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { userInfo } from '../../interfaces/GameTableInterfaces'
import { loadingStatus } from '../../interfaces/MainInterfaces'
import AuthAPI from '../../services/AuthAPI'
import { RootState } from '../store'


interface initState {
  topUsers: {
    status: loadingStatus,
    topByPoints: {
      country: userInfo[],
      all: userInfo[],
    },
    topByCrazyPoints: {
      country: userInfo[],
      all: userInfo[]
    },
    topByPhoto: {
      country: userInfo[],
      all: userInfo[]
    },
    topByWonMoney: {
      country: userInfo[],
      all: userInfo[]
    },
    topBySpendMoney: {
      country: userInfo[],
      all: userInfo[]
    },
  },

}

const initialState: initState = {
  topUsers: {
    status: 'loading',
    topByPoints: {
      all: [],
      country: [],
    },
    topByCrazyPoints: {
      all: [],
      country: [],
    },
    topByPhoto: {
      all: [],
      country: [],
    },
    topByWonMoney: {
      all: [],
      country: [],
    },
    topBySpendMoney: {
      all: [],
      country: [],
    },
  },
}

export const getTopUsersByPoints = createAsyncThunk(
  '/user/getTopUsersByPoints',
  async ({
    gender,
    country,
  }: {
    gender: number,
    country: boolean
  }) => {
    const response = await AuthAPI.get(`/user/top-point?skip=0&take=100&gender=${gender}&country=${country}`)

    return {
      data: response.data,
      country,
    }
  },
)

export const getTopUsersByCrazyPoints = createAsyncThunk(
  '/user/getTopUsersByCrazyPoints',
  async ({
    gender,
    country,
  }: {
    gender: number,
    country: boolean
  }) => {
    const response = await AuthAPI.get(`/user/top-crazy-point?skip=0&take=100&gender=${gender}&country=${country}`)

    return {
      data: response.data,
      country,
    }
  },
)

export const getTopUsersByPhotoRatings = createAsyncThunk(
  '/user/getTopUsersByPhotoRatings',
  async ({
    gender,
    country,
  }: {
    gender: number,
    country: boolean
  }) => {
    const response = await AuthAPI.get(`/user/top-multimedia?skip=0&take=100&country=${country}&gender=${gender}`)

    return {
      data: response.data,
      country,
    }
  },
)

export const getTopUsersByWonMoney = createAsyncThunk(
  '/user/getTopUsersByWonMoney',
  async ({
    gender,
    country,
  }: {
    gender: number,
    country: boolean
  }) => {
    const response = await AuthAPI.get(`/user/prize-gold/top?skip=0&take=100&country=${country}&gender=${gender}`)

    return {
      data: response.data,
      country,
    }
  },
)

export const getTopUsersByDiamondSpend = createAsyncThunk(
  '/user/getTopUsersByDiamondSpend',
  async ({
    gender,
    country,
  }: {
    gender: number,
    country: any
  }) => {
    const response = await AuthAPI.post('/user/top-spender', {
      skip: 0,
      take: 100,
      currency: 1,
      income: false,
      toLow: true,
      gender,
      ISO: country,
    })

    return {
      data: response.data,
      country,
    }
  },
)

const topList = createSlice({
  name: 'topList',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getTopUsersByPoints.pending, (state) => {
      state.topUsers.status = 'loading'
      state.topUsers.topByPoints.all = []
      state.topUsers.topByPoints.country = []
    })
    builder.addCase(getTopUsersByPoints.fulfilled, (state, action) => {
      state.topUsers.status = 'loaded'

      if (action.payload.country) {
        state.topUsers.topByPoints.country = action.payload.data.usersList
      } else {
        state.topUsers.topByPoints.all = action.payload.data.usersList
      }
    })

    builder.addCase(getTopUsersByCrazyPoints.pending, (state) => {
      state.topUsers.status = 'loading'
      state.topUsers.topByCrazyPoints.all = []
      state.topUsers.topByCrazyPoints.country = []
    })
    builder.addCase(getTopUsersByCrazyPoints.fulfilled, (state, action) => {
      state.topUsers.status = 'loaded'

      if (action.payload.country) {
        state.topUsers.topByCrazyPoints.country = action.payload.data.usersList
      } else {
        state.topUsers.topByCrazyPoints.all = action.payload.data.usersList
      }
    })

    builder.addCase(getTopUsersByPhotoRatings.pending, (state) => {
      state.topUsers.status = 'loading'
      state.topUsers.topByPhoto.all = []
      state.topUsers.topByPhoto.country = []
    })
    builder.addCase(getTopUsersByPhotoRatings.fulfilled, (state, action) => {
      state.topUsers.status = 'loaded'

      if (action.payload.country) {
        state.topUsers.topByPhoto.country = action.payload.data.usersList
      } else {
        state.topUsers.topByPhoto.all = action.payload.data.usersList
      }
    })

    builder.addCase(getTopUsersByWonMoney.pending, (state) => {
      state.topUsers.status = 'loading'
      state.topUsers.topByWonMoney.all = []
      state.topUsers.topByWonMoney.country = []
    })
    builder.addCase(getTopUsersByWonMoney.fulfilled, (state, action) => {
      state.topUsers.status = 'loaded'

      if (action.payload.country) {
        state.topUsers.topByWonMoney.country = action.payload.data.usersList
      } else {
        state.topUsers.topByWonMoney.all = action.payload.data.usersList
      }
    })

    builder.addCase(getTopUsersByDiamondSpend.pending, (state) => {
      state.topUsers.status = 'loading'
      state.topUsers.topBySpendMoney.all = []
      state.topUsers.topBySpendMoney.country = []
    })
    builder.addCase(getTopUsersByDiamondSpend.fulfilled, (state, action) => {
      state.topUsers.status = 'loaded'

      if (action.payload.country) {
        state.topUsers.topBySpendMoney.country = action.payload.data.userList
      } else {
        state.topUsers.topBySpendMoney.all = action.payload.data.userList
      }
    })
  },
})

export const getTopUsersInfo = (state: RootState) => state.topList.topUsers;

export default topList.reducer