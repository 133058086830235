import VKSocialLogin, { GoogleSocialLogin, OKSocialLogin } from '../../../../utils/socialAuth/vkSocialAuth';
import AuthWithSocialItem from './AuthWithSocialItem';

import appleIcon from '../../../../assets/images/social-icons/apple-icon.jpg'
import googleIcon from '../../../../assets/images/social-icons/google.png'
import vkIcon from '../../../../assets/images/social-icons/vk-id-icon.png'
import okIcon from '../../../../assets/images/social-icons/ok-icon.png'
import AuthWithFB from './AuthWithFB';
import AuthWithVk from './AuthWithVK';

export default function AuthWithSocial() {
  return (
    <div className='auth-social'>
      <div className='auth-social-head'>
        <div />
        <span>or</span>
        <div />
      </div>
      <div className='auth-social-body'>
        <AuthWithFB />
        <AuthWithSocialItem
          img={vkIcon}
          onClick={VKSocialLogin}
        />
        <AuthWithSocialItem
          img={googleIcon}
          onClick={GoogleSocialLogin}
        />
        <AuthWithSocialItem
          img={okIcon}
          onClick={OKSocialLogin}
        />
        {/* <AuthWithSocialItem
          img={appleIcon}
          text='Apple'
          onClick={() => { }}
        /> */}
        {/* <AuthWithVk /> */}
      </div>
    </div>
  )
}