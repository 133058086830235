import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import AuthAPI from '../../services/AuthAPI'
import { RootState } from '../store'

export interface isSaleData {
  serverDate: string,
  isSale: boolean
}

interface initState {
  sale: isSaleData
}

const initialState: initState = {
  sale: {
    serverDate: '',
    isSale: false,
  },
}

export const facebookPurchase = createAsyncThunk(
  'transactions/facebookPurchase',
  async (signedRequest: string) => {
    const response = await AuthAPI.post(
      '/order/payment-fb',
      {
        signedRequest,
      },
    )
  },
)

export const getIsSale = createAsyncThunk(
  'transactions/getIsSale',
  async () => {
    const response = await AuthAPI.get('/order/is-sale')

    return response.data;
  },
)

const transactions = createSlice({
  name: 'transactions',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getIsSale.fulfilled, (state, action) => {
      state.sale = action.payload
    })
  },
})

export const getIsSaleData = (state: RootState) => state.transactions.sale;

export default transactions.reducer