import getUserGenderForVk, { getUserInterestedInForVk } from '../../utils/users/getUserGender';
import countriesInRussian from '../../utils/countries/countriesInRussian';
import { MailUserInfo } from '../../interfaces/GameTableInterfaces';
import connectToSocket from '../../utils/socket/connectToSocket';
import imageUpload from '../../utils/upload/cloudinaryUpload';

export default function MailruInit({ getUser, updateUser, updateUserMoney }: { getUser: any, updateUser: any, updateUserMoney: any }) {
  const { mailru }: any = window
  mailru.loader.require('api', () => {
    mailru.app.init('dca74a64bb76185e67f0b16219aa0bb6');
    // все готово, здесь можно работать с функциями API

    mailru.common.users.getInfo((usersList) => {
      const MailUserInfo: MailUserInfo = usersList[0]

      getUser({ id: MailUserInfo.uid }).then(async response => {
        const { user, token } = response.payload;
        if (!user.firstName || !user.birthday) {
          const avatar: any = await imageUpload(MailUserInfo.pic_big) || ''

          const getBirthday = (bdateVisibility: number) => {
            if (bdateVisibility === 1 && MailUserInfo.birthday) {
              return new Date(MailUserInfo.birthday)
            }

            return null
          }

          const data = {
            ISO: MailUserInfo?.location?.country ? countriesInRussian.find(item => item.text === MailUserInfo.location.country.name)?.value?.toUpperCase() : 'RU',
            firstName: MailUserInfo.first_name,
            lastName: MailUserInfo.last_name,
            sex: getUserGenderForVk(MailUserInfo.sex),
            interestedIn: getUserInterestedInForVk(MailUserInfo.sex),
            birthday: getBirthday(MailUserInfo.show_age),
            avatar: avatar?.data.public_id,
            interfaceLang: 'RU',
          }

          updateUser(data, user.id).then(() => {
            getUser({ id: MailUserInfo.uid }).then(response => {
              const { user, token } = response.payload;
              connectToSocket(token, user, false)
            })
          })
        } else {
          connectToSocket(token, user, true)
        }
      });
    });
  })
}