// eslint-disable-next-line import/prefer-default-export
export enum UserInterested {
  male,
  female,
  notMatter,
}

export enum GenderEnum {
  male,
  female,
}