import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { ConversationItem } from '../../interfaces/MessagesInterfaces'
import { groupChatIconsUpload } from '../../utils/upload/bunnyUpload'
import AuthAPI from '../../services/AuthAPI'
import { RootState } from '../store'

export interface privateMessageItem {
  createdAt: string
  id: number
  readers: {
    id: number,
    firstName: string,
    lastName: string,
    avatar: ''
  }[]
  sender: {
    id: number,
    firstName: string,
    lastName: string,
    avatar: ''
  }
  text: string
  type: number
  url: null | string
}

interface initState {
  privateMessages: {
    list: ConversationItem[],
    open: boolean,
    chatId: number | null,
    takeCount: number
  },
  privateChat: {
    list: privateMessageItem[] | null,
    status: 'loading' | 'loaded',
    count: number
  }
}

const initialState: initState = {
  privateMessages: {
    list: [],
    open: false,
    chatId: null,
    takeCount: 1000,
  },
  privateChat: {
    list: null,
    status: 'loading',
    count: 0,
  },
}

export const setMessagesReaded = createAsyncThunk(
  'messages/setMessagesReaded',
  async (id: number) => {
    const response = await AuthAPI.post(`/message/set/readed/all/${id}`)

    return response.data
  },
)

export const getPrivateMessages = createAsyncThunk(
  'messages/getMessages',
  async (search: string) => {
    const response = await AuthAPI.get(`/message/chat-list?${search}`);

    return response.data;
  },
)

export const addChat = createAsyncThunk(
  'messages/addChat',
  async ({
    receiverIds,
    title = null,
    url = null,
    isDirect,
  }: {
    receiverIds: number[],
    title?: string | null,
      url?: File | null,
      isDirect: boolean
  }) => {
    let newUrl: File | string | null = url;

    if (url) {
      newUrl = await groupChatIconsUpload(url)
    }

    const response = await AuthAPI.post('/message/chat-add', {
      receiverIds,
      title,
      url: newUrl,
      isDirect,
    });

    return response.data;
  },
)

export const chatExists = createAsyncThunk(
  'messages/chatExists',
  async (id: number) => {
    const response = await AuthAPI.post(`/message/chat-exist/${id}`);

    return response.data;
  },
)

export const getChat = createAsyncThunk(
  'messages/getChat',
  async (data: {
    id: number,
    count?: number
  }) => {
    const response = await AuthAPI.get(`/message/message-list-by-chat/${data.id}?skip=0&take=${data.count || 20}`);

    return response.data;
  },
)

export const removeChatMember = createAsyncThunk(
  'messages/removeChatMember',
  async (data: {
    chatId: number,
    userId: number,
  }) => {
    const response = await AuthAPI.put('/message/remove-chat-member', data);

    return response.data;
  },
)
export const addChatMember = createAsyncThunk(
  'messages/addChatMember',
  async (data: {
    chatId: number,
    userIds: number[],
  }) => {
    const response = await AuthAPI.put('/message/add-chat-member', data);

    return response.data;
  },
)

const messagesSlice = createSlice({
  name: 'messages',
  initialState,
  reducers: {
    openPrivateMessagesModal: (state) => {
      state.privateMessages.open = true
    },
    closePrivateMessagesModal: (state) => {
      state.privateMessages.open = false
    },
    changePrivateMessagesData: (state, action) => {
      state.privateMessages = {
        ...state.privateMessages,
        ...action.payload,
      }
    },
    changePrivateChat: (state, action) => {
      state.privateChat = {
        ...state.privateChat,
        ...action.payload,
      }
    },

  },
  extraReducers: (builder) => {
    builder.addCase(getPrivateMessages.fulfilled, (state, action) => {
      state.privateMessages.list = action.payload.response.list
    })
    builder.addCase(getChat.pending, (state) => {
      state.privateChat.status = 'loading';
    })
    builder.addCase(getChat.fulfilled, (state, action) => {
      state.privateChat = {
        ...action.payload,
        status: 'loaded',
      };
    })
  },
})

export const {
  openPrivateMessagesModal,
  closePrivateMessagesModal,
  changePrivateMessagesData,
  changePrivateChat,
} = messagesSlice.actions

export const getPrivateMessagesList = (state: RootState) => state.messages.privateMessages.list
export const getPrivateMessagesInfo = (state: RootState) => state.messages.privateMessages
export const getPrivateChat = (state: RootState) => state.messages.privateChat

export default messagesSlice.reducer