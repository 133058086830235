/* eslint-disable no-useless-escape */
import { useState } from 'react'
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import {
  FormControl, FormHelperText, TextField, Button, InputLabel, CircularProgress,
} from '@mui/material';
import AuthFormHead from '../../../../components/Authentication/Layouts/AuthFormHead';
import { loadingStatus } from '../../../../interfaces/MainInterfaces';
import { checkCode } from '../../../../app/slices/authSlice';
import { useAppDispatch } from '../../../../app/hooks';
import { AuthButton } from '../../../../components/Authentication';

const styles = {
  textField: {
    backgroundColor: '#fff',
    width: '100%',
    outline: 'none',
    borderRadius: '5px',
  },
  input: {
    outline: 'none',
  },
}

export default function CheckCode({
  handleChangePage,
}: {
  handleChangePage: (page: null | number) => void
}) {
  const { t } = useTranslation()

  const dispatch = useAppDispatch()

  const {
    handleSubmit, control, formState: { errors },
  } = useForm({
    defaultValues: {
      code: '',
    },
  });

  const [loading, setLoading] = useState<loadingStatus>('loaded')

  const onSubmit = (data: any) => {
    const email = sessionStorage.getItem('reset_pass_email')
    if (email && data.code) {
      setLoading('loading')

      dispatch(checkCode({
        code: +data.code,
        email,
        cb: () => {
          handleChangePage(2)
        },
      })).then((response: any) => {
        if (response?.error) {
          setLoading('loaded')
        }
      })
    }
  };

  return (
    <div className='auth-form'>
      <AuthFormHead
        title='Проверьте почту'
        text='Мы отправили код подтверждения'
      />
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormControl variant='outlined' className='auth-form-body-input'>
          <Controller
            control={control}
            name='code'
            rules={{
              required: {
                value: true,
                message: t('errors.required'),
              },
            }}
            render={({
              field: {
                onChange, value,
              },
            }) => (
              <TextField
                style={styles.textField}
                inputProps={styles.input}
                id='reset-pass-code'
                placeholder='Code'
                className='auth-input'
                variant='outlined'
                error={!!errors.code}
                value={value}
                onChange={onChange}
              />
            )}
          />
          {!!errors.code && (
            <FormHelperText error id='accountId-error'>
              {errors.code.message}
            </FormHelperText>
          )}
        </FormControl>
        <AuthButton loading={loading === 'loading'}>Отправить</AuthButton>
      </form>
    </div>
  )
}