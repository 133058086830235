interface props {
  img: any,
  onClick: () => any
}

export default function AuthWithSocialItem({
  img,
  onClick,
}: props) {
  return (
    <div className='auth-social-item' onClick={onClick}>
      <img src={img} alt='' />
    </div>
  )
}