import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'
import AuthAPI from '../../services/AuthAPI'
import { RootState } from '../store'

interface initState {
  socialAuth: {
    user: any,
    socialType: string
  }
}

const initialState: initState = {
  socialAuth: {
    user: {},
    socialType: '',
  },
}

export const sendCheckCode = createAsyncThunk(
  'auth/sendCheckCode',
  async (data: any) => {
    const { email, interfaceLang, cb } = data;
    const response = await AuthAPI.post('/auth/password/message', {
      interfaceLang,
      email,
    })

    // cb()

    return response.data;
  },
)

export const checkCode = createAsyncThunk(
  'auth/checkCode',
  async (data: {
    email: string,
    code: number,
    cb: any
  }) => {
    const { email, code, cb } = data;

    const response = await AuthAPI.post('/auth/check-code', {
      email,
      code,
    })

    cb()

    return response.data;
  },
)

export const changePassword = createAsyncThunk(
  'auth/changePassword',
  async (data: {
    email: string,
    password: string
  }) => {
    const response = await AuthAPI.post('/auth/password/change/by-code', data)

    return response.data;
  },
)

export const socialSave = createAsyncThunk(
  'auth/socialSave',
  async (data: {
    socialId: string,
    socialType: string
  }, { rejectWithValue }) => {
    try {
      const response = await AuthAPI.post('/auth/social/save', data)

      return response.data;
    } catch (error: any) {
      if (!error?.response) {
        throw error
      }

      return rejectWithValue(error.response.data)
    }
  },
)

export const changeBySocial = createAsyncThunk(
  'auth/changeBySocial',
  async (data: {
    password: string,
    socialId: string,
    socialType: string,
    changeBySocial: boolean,
  }, { rejectWithValue }) => {
    try {
      const response = await AuthAPI.post('/auth/social/change/profile', data)

      return response.data;
    } catch (error: any) {
      if (!error?.response) {
        throw error
      }



      return rejectWithValue(error.response.data)
    }
  },
)

const auth = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    saveSocialAuthData: (state, action) => {
      state.socialAuth = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(changeBySocial.rejected, (state, action: any) => {
      toast.error(action.payload.message)
    })

    builder.addCase(checkCode.rejected, () => {
      toast.error('Неверный код')
    })
  },
})

export const { saveSocialAuthData } = auth.actions;

export const getSocialAuthData = (state: RootState) => state.auth.socialAuth

export default auth.reducer