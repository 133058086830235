import { useCallback } from 'react';
import FacebookAuth from 'react-facebook-auth';
import { saveSocialAuthData } from '../../../../../app/slices/authSlice';
import { useAppDispatch } from '../../../../../app/hooks';

import fbIcon from '../../../../../assets/images/social-icons/facebook.png'

const btnStyles = {
  backgroundColor: '#008CBA',
  border: 'none',
  color: 'white',
  padding: '15px 32px',
  textAlign: 'center',
  textDecoration: 'none',
  display: 'inline-block',
  fontSize: '16px',
  margin: '4px 2px',
  cursor: 'pointer',
};

function AuthWithOkButton({ onClick }: { onClick: () => void }) {
  return <div className='auth-social-item' onClick={onClick}>
    <img src={fbIcon} alt='' />
  </div>
}

export default function AuthWithFB() {
  const dispatch = useAppDispatch()

  const handleAuth = useCallback((response) => {
    const [firstName, lastName] = response.name.split(' ')

    const user = {
      firstName,
      lastName,
      avatar: response.picture.data.url,
      id: response.userID,
    }

    dispatch(saveSocialAuthData({
      socialType: 'fb',
      user,
    }))
  }, [])

  return <FacebookAuth
    appId='1001567901317616'
    callback={handleAuth}
    component={AuthWithOkButton}
    customProps={{ styles: btnStyles }}
  />
}