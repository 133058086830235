import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import AuthAPI from '../../services/AuthAPI'
import { RootState } from '../store'

export interface newsItem {
  id: number,
  title: string,
  description_en: string,
  description_jp: string,
  description_ru: string,
  description_cn: string,
  description_kr: string,
  description_fr: string,
  description_es: string,
  url: string,
  visible: boolean,
  createdAt: string
}

interface initState {
  news: {
    list: newsItem[],
    count: 0
  }
}

const initialState: initState = {
  news: {
    list: [],
    count: 0,
  },
}

export const getNews = createAsyncThunk(
  'news/getNews',
  async (search: string) => {
    const response = await AuthAPI.get(`/news/get-meny?${search}`);

    return response.data
  },
)


const news = createSlice({
  name: 'news',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getNews.fulfilled, (state, action) => {
      const [list, count] = action.payload.response;

      state.news = { list, count };
    })
  },
})

export const getNewsData = (state: RootState) => state.news.news;

export default news.reducer