import { useState, useCallback, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import {
  DialogActions, DialogContent, DialogTitle, Button, Dialog,
  FormHelperText, InputLabel, MenuItem, Select, TextField,
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { GenderEnum, UserInterested } from '../../../features/Authentication/utils/enums';
import countries, { languages } from '../../../features/Authentication/utils/countries';
import { getMe, updateUserInfo } from '../../../app/slices/usersSlice';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import connectToSocket from '../../../utils/socket/connectToSocket';
import { getFBDataInfo } from '../../../app/slices/gameInfoSlice';
import getCurrentEnv from '../../../utils/variables';
import './style.scss'

const styles = {
  textField: {
    backgroundColor: '#fff',
    width: '100%',
    outline: 'none',
    borderRadius: '5px',
  },
  input: {
    outline: 'none',
  },
}

export default function FBUpdateInfoBanner() {
  const { t } = useTranslation()

  const dispatch = useAppDispatch()

  const fbData = useAppSelector(getFBDataInfo)

  const {
    handleSubmit, control, formState: { errors }, reset,
  } = useForm({
    defaultValues: {
      firstName: '',
      lastName: '',
      sex: GenderEnum.male,
      birthday: dayjs().subtract(18, 'year'),
      ISO: 'US',
      interfaceLang: 'en',
      interestedIn: UserInterested.female,
      avatar: '',
    },
  });

  const [open, setOpen] = useState(false);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const onSubmit = useCallback((data: any) => {
    handleClose()
    dispatch(updateUserInfo({
      data, id: fbData.id,
    })).then((response: any) => {
      if (!response.error) {
        dispatch(getMe({
          socialId: fbData.socialId,
          socialType: 'fb',
        })).then((response: any) => {
          if (!response.error) {
            const { user, token } = response.payload;
            connectToSocket(token, user, false)
          }
        })
      }
    })
  }, [fbData]);

  useEffect(() => {
    reset(formValues => ({
      ...formValues,
      avatar: fbData.avatar,
      birthday: fbData.birthday ? dayjs(fbData.birthday) : dayjs().subtract(18, 'year'),
      firstName: fbData.firstName,
      lastName: fbData.lastName,
      sex: fbData.gender || GenderEnum.male,
      interestedIn: fbData.interestedIn || GenderEnum.female,
      interfaceLang: fbData.interfaceLang || 'en',
      ISO: fbData.ISO || 'US',
    }))
  }, [fbData])

  useEffect(() => {
    if (fbData.update && getCurrentEnv() === 'fb') {
      setOpen(true)
    } else {
      setOpen(false)
    }
  }, [fbData])

  return (
    <Dialog
      open={open}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      className='fbupdate-info'
    >
      <DialogTitle id='alert-dialog-title'>
        {t('registration.fillForms')}
      </DialogTitle>
      <DialogContent>
        <form className='fbupdate-info-form' onSubmit={handleSubmit(onSubmit)}>
          <InputLabel
            htmlFor='component-simple'
            style={{
              color: '#fff',
              fontWeight: 700,
              fontSize: '12px',
              marginBottom: '5px',
            }}
          >
            {t('registration.firstName')}
          </InputLabel>
          <Controller
            control={control}
            name='firstName'
            rules={{
              required: {
                value: true,
                message: t('errors.required'),
              },
            }}
            render={({
              field: {
                onChange, value,
              },
            }) => (
              <TextField
                style={styles.textField}
                inputProps={styles.input}
                id='outlined-basic'
                placeholder='John'
                variant='outlined'
                error={!!errors.firstName}
                value={value}
                onChange={onChange}
              />
            )}
          />
          {!!errors.firstName && (
            <FormHelperText error id='accountId-error'>
              {errors.firstName.message}
            </FormHelperText>
          )}
          <InputLabel
            htmlFor='component-simple'
            style={{
              color: '#fff',
              fontWeight: 700,
              fontSize: '12px',
              marginBottom: '5px',
            }}
          >
            {t('registration.lastName')}
          </InputLabel>
          <Controller
            control={control}
            name='lastName'
            rules={{
              required: {
                value: true,
                message: t('errors.required'),
              },
            }}
            render={({
              field: {
                onChange, value,
              },
            }) => (
              <TextField
                style={styles.textField}
                inputProps={styles.input}
                id='outlined-basic'
                placeholder='Smith'
                variant='outlined'
                error={!!errors.lastName}
                value={value}
                onChange={onChange}
              />
            )}
          />
          {!!errors.lastName && (
            <FormHelperText error id='accountId-error'>
              {errors.lastName.message}
            </FormHelperText>
          )}
          <InputLabel
            htmlFor='component-simple'
            style={{
              color: '#fff',
              fontWeight: 700,
              fontSize: '12px',
              marginBottom: '5px',
            }}
          >
            {t('registration.gender')}
          </InputLabel>
          <Controller
            control={control}
            name='sex'
            render={({
              field: {
                onChange, value,
              },
            }) => (
              <Select
                className='gender-select'
                labelId='demo-simple-select-label'
                style={styles.textField}
                inputProps={styles.input}
                id='demo-simple-select'
                value={value}
                onChange={onChange}
              >
                <MenuItem className='genderSelectorMenuItem' value={GenderEnum.male}>
                  {t('registration.male')}
                </MenuItem>
                <MenuItem className='genderSelectorMenuItem' value={GenderEnum.female}>
                  {t('registration.female')}
                </MenuItem>
              </Select>
            )}
          />
          <InputLabel
            htmlFor='component-simple'
            style={{
              color: '#fff',
              fontWeight: 700,
              fontSize: '12px',
              marginBottom: '5px',
            }}
          >
            {t('registration.interestedIn')}
          </InputLabel>
          <Controller
            control={control}
            name='interestedIn'
            rules={{
              required: {
                value: true,
                message: t('errors.required'),
              },
            }}
            render={({
              field: {
                onChange, value,
              },
            }) => (
              <Select
                style={styles.textField}
                inputProps={styles.input}
                labelId='demo-simple-select-label'
                id='demo-simple-select'
                className='interestedIn-select'
                value={value}
                onChange={onChange}
              >
                <MenuItem value={UserInterested.male}>{t('registration.male')}</MenuItem>
                <MenuItem value={UserInterested.female}>{t('registration.female')}</MenuItem>
                {/* <MenuItem value={UserInterested.notMatter}>{t('registration.noMatter')}</MenuItem> */}
              </Select>
            )}
          />
          <Controller
            control={control}
            name='birthday'
            render={({
              field: {
                onChange, value,
              },
            }) => (
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label={t('profile.info.birthDate')}
                  value={value}
                  PopperProps={{ sx: { zIndex: 1000000 } }}
                  InputProps={{ sx: { background: '#fff' } }}
                  onChange={onChange}
                  maxDate={dayjs().subtract(18, 'year')}
                  renderInput={(params) => <TextField className='age-modal-input-field' {...params} />}
                />
              </LocalizationProvider>
            )}
          />
          {!!errors.birthday && (
            <FormHelperText error id='accountId-error'>
              {errors.birthday.message}
            </FormHelperText>
          )}
          <InputLabel
            htmlFor='component-simple'
            style={{
              color: '#fff',
              fontWeight: 700,
              fontSize: '12px',
              marginBottom: '5px',
            }}
          >
            {t('registration.country')}
          </InputLabel>
          <Controller
            control={control}
            name='ISO'
            rules={{
              required: {
                value: true,
                message: t('errors.required'),
              },
            }}
            render={({
              field: {
                onChange, value,
              },
            }) => (
              <Select
                style={styles.textField}
                inputProps={styles.input}
                labelId='demo-simple-select-label'
                id='demo-simple-select'
                className='country-select'
                value={value}
                onChange={onChange}
              >
                {
                  countries.map((item, index) => <MenuItem
                    key={index}
                    value={item.code}
                  >
                    {item.name}
                  </MenuItem>)
                }
              </Select>
            )}
          />
          <InputLabel
            className='interfaceLanguageLable'
            htmlFor='component-simple'
            style={{
              color: '#fff',
              fontWeight: 700,
              fontSize: '12px',
              marginBottom: '5px',
            }}
          >
            {t('registration.interfaceLanguage')}
          </InputLabel>
          <Controller
            control={control}
            name='interfaceLang'
            rules={{
              required: {
                value: true,
                message: t('errors.required'),
              },
            }}
            render={({
              field: {
                onChange, value,
              },
            }) => (
              <Select
                style={styles.textField}
                inputProps={styles.input}
                labelId='demo-simple-select-label'
                id='demo-simple-select'
                className='interfaceLang-select'
                value={value}
                onChange={onChange}
              >
                {
                  languages.filter(country => ['en', 'es', 'ch', 'fr', 'ru', 'ko', 'ja'].includes(country.code)).map((item, index) => <MenuItem
                    value={item.code}
                    key={index}
                  >
                    {item.name}
                  </MenuItem>)
                }
              </Select>
            )}
          />
          <Button type='submit' autoFocus>
            {t('buttons.save')}
          </Button>
        </form>
      </DialogContent>
      <DialogActions />
    </Dialog>
  );
}