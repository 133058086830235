/* eslint-disable camelcase */
/*
* Инициализация API
*/

import { getUserGenderForOk, getUserInterestedInForOk } from '../../utils/users/getUserGender';
import connectToSocket from '../../utils/socket/connectToSocket';
import imageUpload from '../../utils/upload/cloudinaryUpload';

export default function OKInit({ getUser, updateUser, updateUserMoney }: { getUser: any, updateUser: any, updateUserMoney: any }) {
  const API = (window as any).FAPI
  const rParams = API.Util.getRequestParameters();

  API.init(
    rParams.api_server,
    rParams.apiconnection,
    /*
      * Первый параметр:
      * функция, которая будет вызвана после успешной инициализации.
    */
    () => {
      API.Client.call({ method: 'users.getCurrentUser' }, (status, data, error) => {
        const userInfo = data;

        if (data) {
          getUser(data).then(async response => {
            const { user, token } = response.payload;

            const getBirthday = (bdate: number) => {
              if (bdate) {
                return new Date(bdate)
              }
              return null
            }

            if (!user.firstName || !user.birthday) {
              const avatar: any = await imageUpload(userInfo.pic_3) || ''
              const data = {
                ISO: userInfo?.location?.countryCode || 'RU',
                firstName: userInfo.first_name,
                lastName: userInfo.last_name,
                sex: getUserGenderForOk(userInfo.gender),
                interestedIn: getUserInterestedInForOk(userInfo.gender),
                birthday: getBirthday(userInfo.birthday),
                avatar: avatar?.data.public_id,
                interfaceLang: 'RU',
              }
              updateUser(data, user.id).then(() => {
                getUser({ uid: userInfo.uid }).then(response => {
                  const { user, token } = response.payload;
                  connectToSocket(token, user, false)
                })
              })
            } else {
              connectToSocket(token, user, true)
            }
          })
        }
      })



      API.UI.getPageInfo();
    },
  );


  window.addEventListener('showPayment', updateUserMoney)
}