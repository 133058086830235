const countriesInRussian = [
  {
    text: 'Австралия',
    value: 'AU',
  },
  {
    text: 'Австрия',
    value: 'AT',
  },
  {
    text: 'Азербайджан',
    value: 'AZ',
  },
  {
    text: 'Аландские острова',
    value: 'AX',
  },
  {
    text: 'Албания',
    value: 'AL',
  },
  {
    text: 'Алжир',
    value: 'DZ',
  },
  {
    text: 'Виргинские Острова (США)',
    value: 'VI',
  },
  {
    text: 'Американское Самоа',
    value: 'AS',
  },
  {
    text: 'Ангилья',
    value: 'AI',
  },
  {
    text: 'Ангола',
    value: 'AO',
  },
  {
    text: 'Андорра',
    value: 'AD',
  },
  {
    text: 'Антарктида',
    value: 'AQ',
  },
  {
    text: 'Антигуа и Барбуда',
    value: 'AG',
  },
  {
    text: 'Аргентина',
    value: 'AR',
  },
  {
    text: 'Армения',
    value: 'AM',
  },
  {
    text: 'Аруба',
    value: 'AW',
  },
  {
    text: 'Афганистан',
    value: 'AF',
  },
  {
    text: 'Багамские Острова',
    value: 'BS',
  },
  {
    text: 'Бангладеш',
    value: 'BD',
  },
  {
    text: 'Барбадос',
    value: 'BB',
  },
  {
    text: 'Бахрейн',
    value: 'BH',
  },
  {
    text: 'Белиз',
    value: 'BZ',
  },
  {
    text: 'Белоруссия',
    value: 'BY',
  },
  {
    text: 'Бельгия',
    value: 'BE',
  },
  {
    text: 'Бенин',
    value: 'BJ',
  },
  {
    text: 'Бермуды',
    value: 'BM',
  },
  {
    text: 'Болгария',
    value: 'BG',
  },
  {
    text: 'Боливия',
    value: 'BO',
  },
  {
    text: 'Бонэйр, Синт-Эстатиус и Саба',
    value: 'BQ',
  },
  {
    text: 'Босния и Герцеговина',
    value: 'BA',
  },
  {
    text: 'Ботсвана',
    value: 'BW',
  },
  {
    text: 'Бразилия',
    value: 'BR',
  },
  {
    text: 'Британская территория в Индийском океане',
    value: 'IO',
  },
  {
    text: 'Виргинские Острова (Великобритания)',
    value: 'VG',
  },
  {
    text: 'Бруней',
    value: 'BN',
  },
  {
    text: 'Буркина-Фасо',
    value: 'BF',
  },
  {
    text: 'Бурунди',
    value: 'BI',
  },
  {
    text: 'Бутан',
    value: 'BT',
  },
  {
    text: 'Вануату',
    value: 'VU',
  },
  {
    text: 'Ватикан',
    value: 'VA',
  },
  {
    text: 'Великобритания',
    value: 'GB',
  },
  {
    text: 'Венгрия',
    value: 'HU',
  },
  {
    text: 'Венесуэла',
    value: 'VE',
  },
  {
    text: 'Внешние малые острова (США)',
    value: 'UM',
  },
  {
    text: 'Восточный Тимор',
    value: 'TL',
  },
  {
    text: 'Вьетнам',
    value: 'VN',
  },
  {
    text: 'Габон',
    value: 'GA',
  },
  {
    text: 'Гаити',
    value: 'HT',
  },
  {
    text: 'Гайана',
    value: 'GY',
  },
  {
    text: 'Гамбия',
    value: 'GM',
  },
  {
    text: 'Гана',
    value: 'GH',
  },
  {
    text: 'Гваделупа',
    value: 'GP',
  },
  {
    text: 'Гватемала',
    value: 'GT',
  },
  {
    text: 'Гвиана',
    value: 'GF',
  },
  {
    text: 'Гвинея',
    value: 'GN',
  },
  {
    text: 'Гвинея-Бисау',
    value: 'GW',
  },
  {
    text: 'Германия',
    value: 'DE',
  },
  {
    text: 'Гернси',
    value: 'GG',
  },
  {
    text: 'Гибралтар',
    value: 'GI',
  },
  {
    text: 'Гондурас',
    value: 'HN',
  },
  {
    text: 'Гонконг',
    value: 'HK',
  },
  {
    text: 'Гренада',
    value: 'GD',
  },
  {
    text: 'Гренландия',
    value: 'GL',
  },
  {
    text: 'Греция',
    value: 'GR',
  },
  {
    text: 'Грузия',
    value: 'GE',
  },
  {
    text: 'Гуам',
    value: 'GU',
  },
  {
    text: 'Дания',
    value: 'DK',
  },
  {
    text: 'Джерси',
    value: 'JE',
  },
  {
    text: 'Джибути',
    value: 'DJ',
  },
  {
    text: 'Доминика',
    value: 'DM',
  },
  {
    text: 'Доминиканская Республика',
    value: 'DO',
  },
  {
    text: 'ДР Конго',
    value: 'CD',
  },
  {
    text: 'Флаг ЕС',
    value: 'Европейский',
  },
  {
    text: 'Египет',
    value: 'EG',
  },
  {
    text: 'Замбия',
    value: 'ZM',
  },
  {
    text: 'САДР',
    value: 'EH',
  },
  {
    text: 'Зимбабве',
    value: 'ZW',
  },
  {
    text: 'Израиль',
    value: 'IL',
  },
  {
    text: 'Индия Индия',
    value: 'IN',
  },
  {
    text: 'Индонезия',
    value: 'ID',
  },
  {
    text: 'Иордания',
    value: 'JO',
  },
  {
    text: 'Ирак',
    value: 'IQ',
  },
  {
    text: 'Иран',
    value: 'IR',
  },
  {
    text: 'Ирландия',
    value: 'IE',
  },
  {
    text: 'Исландия',
    value: 'IS',
  },
  {
    text: 'Испания',
    value: 'ES',
  },
  {
    text: 'Италия',
    value: 'IT',
  },
  {
    text: 'Йемен',
    value: 'YE',
  },
  {
    text: 'Кабо-Верде',
    value: 'CV',
  },
  {
    text: 'Казахстан',
    value: 'KZ',
  },
  {
    text: 'Острова Кайман',
    value: 'KY',
  },
  {
    text: 'Камбоджа',
    value: 'KH',
  },
  {
    text: 'Камерун',
    value: 'CM',
  },
  {
    text: 'Канада',
    value: 'CA',
  },
  {
    text: 'Катар',
    value: 'QA',
  },
  {
    text: 'Кения',
    value: 'KE',
  },
  {
    text: 'Кипр',
    value: 'CY',
  },
  {
    text: 'Киргизия',
    value: 'KG',
  },
  {
    text: 'Кирибати',
    value: 'KI',
  },
  {
    text: 'Китайская Республика',
    value: 'TW',
  },
  {
    text: 'КНДР (Корейская Народно-Демократическая Республика)',
    value: 'KP',
  },
  {
    text: 'Китай (Китайская Народная Республика)',
    value: 'CN',
  },
  {
    text: 'Кокосовые острова',
    value: 'CC',
  },
  {
    text: 'Колумбия',
    value: 'CO',
  },
  {
    text: 'Коморы',
    value: 'KM',
  },
  {
    text: 'Коста-Рика',
    value: 'CR',
  },
  {
    text: 'Кот-д’Ивуар',
    value: 'CI',
  },
  {
    text: 'Куба',
    value: 'CU',
  },
  {
    text: 'Кувейт',
    value: 'KW',
  },
  {
    text: 'Кюрасао',
    value: 'CW',
  },
  {
    text: 'Лаос',
    value: 'LA',
  },
  {
    text: 'Латвия',
    value: 'LV',
  },
  {
    text: 'Лесото',
    value: 'LS',
  },
  {
    text: 'Либерия',
    value: 'LR',
  },
  {
    text: 'Ливан',
    value: 'LB',
  },
  {
    text: 'Ливия',
    value: 'LY',
  },
  {
    text: 'Литва',
    value: 'LT',
  },
  {
    text: 'Лихтенштейн',
    value: 'LI',
  },
  {
    text: 'Люксембург',
    value: 'LU',
  },
  {
    text: 'Маврикий',
    value: 'MU',
  },
  {
    text: 'Мавритания',
    value: 'MR',
  },
  {
    text: 'Мадагаскар',
    value: 'MG',
  },
  {
    text: 'Майотта',
    value: 'YT',
  },
  {
    text: 'Макао',
    value: 'MO',
  },
  {
    text: 'Северная Македония',
    value: 'MK',
  },
  {
    text: 'Малави',
    value: 'MW',
  },
  {
    text: 'Малайзия',
    value: 'MY',
  },
  {
    text: 'Мали',
    value: 'ML',
  },
  {
    text: 'Мальдивы',
    value: 'MV',
  },
  {
    text: 'Мальта',
    value: 'MT',
  },
  {
    text: 'Марокко',
    value: 'MA',
  },
  {
    text: 'Мартиника',
    value: 'MQ',
  },
  {
    text: 'Маршалловы Острова',
    value: 'MH',
  },
  {
    text: 'Мексика',
    value: 'MX',
  },
  {
    text: 'Микронезия',
    value: 'FM',
  },
  {
    text: 'Мозамбик',
    value: 'MZ',
  },
  {
    text: 'Молдавия',
    value: 'MD',
  },
  {
    text: 'Монако',
    value: 'MC',
  },
  {
    text: 'Монголия',
    value: 'MN',
  },
  {
    text: 'Монтсеррат',
    value: 'MS',
  },
  {
    text: 'Мьянма',
    value: 'MM',
  },
  {
    text: 'Намибия',
    value: 'NA',
  },
  {
    text: 'Науру',
    value: 'NR',
  },
  {
    text: 'Непал',
    value: 'NP',
  },
  {
    text: 'Нигер',
    value: 'NE',
  },
  {
    text: 'Нигерия',
    value: 'NG',
  },
  {
    text: 'Нидерланды',
    value: 'NL',
  },
  {
    text: 'Никарагуа',
    value: 'NI',
  },
  {
    text: 'Ниуэ',
    value: 'NU',
  },
  {
    text: 'Новая Зеландия',
    value: 'NZ',
  },
  {
    text: 'Новая Каледония',
    value: 'NC',
  },
  {
    text: 'Норвегия',
    value: 'NO',
  },
  {
    text: 'ОАЭ',
    value: 'AE',
  },
  {
    text: 'Оман',
    value: 'OM',
  },
  {
    text: 'Остров Буве',
    value: 'BV',
  },
  {
    text: 'Остров Мэн',
    value: 'IM',
  },
  {
    text: 'Острова Кука',
    value: 'CK',
  },
  {
    text: 'Остров Норфолк',
    value: 'NF',
  },
  {
    text: 'Остров Рождества',
    value: 'CX',
  },
  {
    text: 'Острова Питкэрн',
    value: 'PN',
  },
  {
    text: 'Острова Святой Елены, Вознесения и Тристан-да-Кунья',
    value: 'SH',
  },
  {
    text: 'Пакистан',
    value: 'PK',
  },
  {
    text: 'Палау',
    value: 'PW',
  },
  {
    text: 'Государство Палестина',
    value: 'PS',
  },
  {
    text: 'Панама',
    value: 'PA',
  },
  {
    text: 'Папуа — Новая Гвинея',
    value: 'PG',
  },
  {
    text: 'Парагвай',
    value: 'PY',
  },
  {
    text: 'Перу',
    value: 'PE',
  },
  {
    text: 'Польша',
    value: 'PL',
  },
  {
    text: 'Португалия',
    value: 'PT',
  },
  {
    text: 'Пуэрто-Рико',
    value: 'PR',
  },
  {
    text: 'Республика Конго',
    value: 'CG',
  },
  {
    text: 'Республика Корея',
    value: 'KR',
  },
  {
    text: 'Реюньон',
    value: 'RE',
  },
  {
    text: 'Россия',
    value: 'RU',
  },
  {
    text: 'Руанда',
    value: 'RW',
  },
  {
    text: 'Румыния',
    value: 'RO',
  },
  {
    text: 'Сальвадор',
    value: 'SV',
  },
  {
    text: 'Самоа',
    value: 'WS',
  },
  {
    text: 'Сан-Марино',
    value: 'SM',
  },
  {
    text: 'Сан-Томе и Принсипи',
    value: 'ST',
  },
  {
    text: 'Саудовская Аравия',
    value: 'SA',
  },
  {
    text: 'Эсватини',
    value: 'SZ',
  },
  {
    text: 'Северные Марианские Острова',
    value: 'MP',
  },
  {
    text: 'Сейшельские Острова',
    value: 'SC',
  },
  {
    text: 'Сен-Бартелеми',
    value: 'BL',
  },
  {
    text: 'Сен-Мартен',
    value: 'MF',
  },
  {
    text: 'Сен-Пьер и Микелон',
    value: 'PM',
  },
  {
    text: 'Сенегал',
    value: 'SN',
  },
  {
    text: 'Сент-Винсент и Гренадины',
    value: 'VC',
  },
  {
    text: 'Сент-Китс и Невис',
    value: 'KN',
  },
  {
    text: 'Сент-Люсия',
    value: 'LC',
  },
  {
    text: 'Сербия',
    value: 'RS',
  },
  {
    text: 'Сингапур',
    value: 'SG',
  },
  {
    text: 'Синт-Мартен',
    value: 'SX',
  },
  {
    text: 'Сирия',
    value: 'SY',
  },
  {
    text: 'Словакия',
    value: 'SK',
  },
  {
    text: 'Словения',
    value: 'SI',
  },
  {
    text: 'Соломоновы Острова',
    value: 'SB',
  },
  {
    text: 'Сомали',
    value: 'SO',
  },
  {
    text: 'Судан',
    value: 'SD',
  },
  {
    text: 'Суринам',
    value: 'SR',
  },
  {
    text: 'США',
    value: 'US',
  },
  {
    text: 'Сьерра-Леоне',
    value: 'SL',
  },
  {
    text: 'Таджикистан',
    value: 'TJ',
  },
  {
    text: 'Таиланд',
    value: 'TH',
  },
  {
    text: 'Танзания',
    value: 'TZ',
  },
  {
    text: 'Теркс и Кайкос',
    value: 'TC',
  },
  {
    text: 'Того',
    value: 'TG',
  },
  {
    text: 'Токелау',
    value: 'TK',
  },
  {
    text: 'Тонга',
    value: 'TO',
  },
  {
    text: 'Тринидад и Тобаго',
    value: 'TT',
  },
  {
    text: 'Тувалу',
    value: 'TV',
  },
  {
    text: 'Тунис',
    value: 'TN',
  },
  {
    text: 'Туркмения',
    value: 'TM',
  },
  {
    text: 'Турция',
    value: 'TR',
  },
  {
    text: 'Уганда',
    value: 'UG',
  },
  {
    text: 'Узбекистан',
    value: 'UZ',
  },
  {
    text: 'Украина',
    value: 'UA',
  },
  {
    text: 'Уоллис и Футуна',
    value: 'WF',
  },
  {
    text: 'Уругвай',
    value: 'UY',
  },
  {
    text: 'Фареры',
    value: 'FO',
  },
  {
    text: 'Фиджи',
    value: 'FJ',
  },
  {
    text: 'Филиппины',
    value: 'PH',
  },
  {
    text: 'Финляндия',
    value: 'FI',
  },
  {
    text: 'Фолклендские острова',
    value: 'FK',
  },
  {
    text: 'Франция',
    value: 'FR',
  },
  {
    text: 'Французская Полинезия',
    value: 'PF',
  },
  {
    text: 'Французские Южные и Антарктические Территории',
    value: 'TF',
  },
  {
    text: 'Херд и Макдональд',
    value: 'HM',
  },
  {
    text: 'Хорватия',
    value: 'HR',
  },
  {
    text: 'ЦАР',
    value: 'CF',
  },
  {
    text: 'Чад',
    value: 'TD',
  },
  {
    text: 'Черногория',
    value: 'ME',
  },
  {
    text: 'Чехия',
    value: 'CZ',
  },
  {
    text: 'Чили',
    value: 'CL',
  },
  {
    text: 'Швейцария',
    value: 'CH',
  },
  {
    text: 'Швеция',
    value: 'SE',
  },
  {
    text: 'Флаг Шпицбергена и Ян-Майена Шпицберген и Ян-Майен',
    value: 'SJ',
  },
  {
    text: 'Шри-Ланка',
    value: 'LK',
  },
  {
    text: 'Эквадор',
    value: 'EC',
  },
  {
    text: 'Экваториальная Гвинея',
    value: 'GQ',
  },
  {
    text: 'Эритрея',
    value: 'ER',
  },
  {
    text: 'Эстония',
    value: 'EE',
  },
  {
    text: 'Эфиопия',
    value: 'ET',
  },
  {
    text: 'ЮАР',
    value: 'ZA',
  },
  {
    text: 'Южная Георгия и Южные Сандвичевы Острова',
    value: 'GS',
  },
  {
    text: 'Южный Судан',
    value: 'SS',
  },
  {
    text: 'Ямайка',
    value: 'JM',
  },
  {
    text: 'Япония',
    value: 'JP',
  },
]

export default countriesInRussian