import getCurrentEnv from '../variables';

export default function getAccessToken() {
  const currentEnv = getCurrentEnv()
  switch (currentEnv) {
    case 'ok':
    case 'fb':
    case 'vk':
    case 'ml':
      return localStorage.getItem(`access_token_${currentEnv}`)
    default:
      return localStorage.getItem('access_token')
  }
}